import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import App from './App';
import * as serviceWorker from './serviceWorker';

import theme from './components/common/theme';
import 'typeface-lato';
import 'typeface-oxygen';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    scroll-behavior: smooth;
    font-size: 14px;
  }

  body {
    padding: 0;
    margin: 0;
    color: ${theme.colors.primaryDark} !important;
    background: ${theme.colors.tertiary} !important;
    font-family: ${theme.fonts.sansSerif} !important;
    font-size: calc(0.25rem + 1vw) !important;
    line-height: calc(1.1rem + 0.5vw);
  }
`;

ReactDOM.render(
  <Fragment>
    <App />
    <GlobalStyle />
  </Fragment>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
