import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Flex, Heading } from './common';

const propTypes = {
  question: PropTypes.shape({
    text: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    exitAnswer: PropTypes.string,
    exitResponse: PropTypes.string,
  }).isRequired,
  handlePressed: PropTypes.func.isRequired,
};

const defaultProps = {};

const Question = props => {
  const { question, handlePressed } = props;

  function buttons() {
    return question.options.map(opt => (
      <Button variant="primary" type="button" onClick={handlePressed} value={opt} m={2}>
        {opt}
      </Button>
    ));
  }

  return (
    <Container
      maxWidth={48}
      w={1}
      px={[2, 3, 4]}
      py={[2, 3]}
      mb={[5, 6]}
      flexDirection="column"
      alignItems="center"
    >
      <Flex minHeight={10} w={1} alignItems="center" justifyContent="center" flexDirection="column">
        <Heading>{question.text}</Heading>
      </Flex>
      <Flex py={3} alignItems="center" justifyContent="center">
        {buttons()}
      </Flex>
    </Container>
  );
};

Question.propTypes = propTypes;
Question.defaultProps = defaultProps;

export default Question;
