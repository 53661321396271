import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import { Button, Heading, Container, theme } from './common';
import { getRequest } from '../utils/network';
import { Table } from 'antd';

const history = createBrowserHistory({
  forceRefresh: true,
});

const Organizations = props => {
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    if (loading) {
      fetchOrganizations(0);
    }
  });

  const fetchOrganizations = page => {
    getRequest('/organizations', { page, per_page: pagination.pageSize }).then(({ data }) => {
      console.log(data);
      setOrgs(data);
      const paginationUpdate = { ...pagination };
      paginationUpdate.current = page;
      paginationUpdate.total = data.count;
      setPagination(paginationUpdate);
      setLoading(false);
    });
  };

  const handleChange = (p, filters, sorter) => {
    fetchOrganizations(p.current);
  };

  const tableLink = {
    color: theme.colors.darkGray,
    fontWeight: '600',
    lineHeight: 1.2,
    textAlign: 'center',
  };

  const columns = [
    {
      title: 'Organization Name',
      dataIndex: 'name',
    },
    {
      title: 'Manager First Name',
      dataIndex: 'user.first_name',
    },
    {
      title: 'Manager Last Name',
      dataIndex: 'user.last_name',
    },
    {
      title: 'Street Address',
      dataIndex: 'address.address_1',
    },
    {
      title: 'City',
      dataIndex: 'address.city',
    },
    {
      title: 'State',
      dataIndex: 'address.state',
    },
    {
      title: 'Zip',
      dataIndex: 'address.postal_code',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, org) => (
        <span>
          <a style={tableLink} href={`/organizations/${org.id}`}>
            Edit
          </a>
        </span>
      ),
    },
  ];

  return (
    <>
      <Heading>Organizations</Heading>
      <Container maxWidth={72} p={[2, 3, 4]} mb={[2, 3]} flexDirection="column" flexAlign="start">
        <Table
          columns={columns}
          dataSource={orgs}
          pagination={pagination}
          onChange={handleChange}
          rowKey={org => org.id}
        />
        <Button onClick={() => history.push('/new_organization')}>Add Organization</Button>
      </Container>
    </>
  );
};

export default Organizations;
