import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, Icon, Layout } from 'antd';
import footerLogo from '../white.svg';

const FooterWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const { Footer } = Layout;

const AppFooter = () => (
  <FooterWrapper>
    <Footer className="footer">
      <div className="footer-wrap">
        <Row gutter={16}>
          <Col md={6} sm={24} xs={24} className="footer-section">
            <div className="footer__logo">
              <img src={footerLogo} alt="logo" height="75" />
            </div>
          </Col>
          <Col md={6} sm={24} xs={24} className="footer-section">
            <div className="footer-center">
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.willingworth.com/faqs"
                >
                  FAQ
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.willingworth.com/resources"
                >
                  Glossary
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.willingworth.com/contact"
                >
                  Contact
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="footer bottom-bar">&copy; 2020 Willingworth. All Rights Reserved.</div>
    </Footer>
  </FooterWrapper>
);

export default AppFooter;
