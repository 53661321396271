import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import { QuestionTypeDefaultProps, QuestionTypePropTypes } from './QuestionPropTypes';
import { NameKeyPropTypes, NameFields } from './NameType';
import { relationshipTypes } from '../../utils/constants';
import { SelectField } from './SelectType';
import { Box, Button, Container, Text } from '../common';
import { UserContext, userRelationshipMap } from '../../contexts/UserContext';
import PrevNextBtnGroup from '../PrevNextBtnGroup';

const RelationshipTypeQuestion = props => {
  const { register, handleSubmit, errors } = useForm();
  const [user, setUser] = useContext(UserContext);
  const { question, keyData, onSubmit, handlePrevious } = props;

  // do any data manipulation before passing off to onSubmit
  const prepData = data => {
    const { firstName, middleName, lastName, suffix, select } = data;
    const response = {
      name: {
        firstName,
        middleName,
        lastName,
        suffix,
      },
      relationship: select,
    };
    return {
      question,
      response,
      relationships: [{ ...response, key: select }],
    };
  };

  // handle submission from form
  const submitHandler = data => {
    const preppedData = prepData(data);
    onSubmit(preppedData);
  };

  const relationshipOptions = {
    ...userRelationshipMap(user.userData.relationships),
    ...question.select,
    Other: {
      display: 'Other',
    },
  };

  return (
    <>
      <Container width={1} maxWidth={28} px={[3, 4]} py={[1, 2]}>
        <Box>
          <NameFields register={register} errors={errors} questionValues={keyData.name || {}} />
          <br />
          <SelectField
            register={register}
            optionList={relationshipOptions}
            defaultValue={keyData.relationship || ''}
            isRequired
          />
        </Box>
        <PrevNextBtnGroup handlePrevious={handlePrevious} />
      </Container>
    </>
  );
};

const RelationshipKeyPropTypes = PropTypes.shape({
  relationship: PropTypes.oneOf(relationshipTypes),
});

RelationshipTypeQuestion.propTypes = {
  ...QuestionTypePropTypes,
  keyData: PropTypes.objectOf(PropTypes.oneOfType([RelationshipKeyPropTypes, NameKeyPropTypes]))
    .isRequired,
};
RelationshipTypeQuestion.defaultProps = QuestionTypeDefaultProps;

export default RelationshipTypeQuestion;

export const exampleRelationshipTypeQuestion = {
  question: {
    ID: 'example-relationshiptype-id',
    questionID: 1,
    question: 'Relationship Details',
    nextQuestionID: 'example-relationshiptype-nextQuestionID',
    keyID: 'example-relationshiptype-keyID',
    select: {
      Mother: {
        display: 'Mother',
        nextQuestion: 'example-mother-nextQuestionID',
      },
      Father: {
        display: 'Father',
        nextQuestion: 'example-father-nextQuestionID',
      },
      Sister: {
        display: 'Sister',
        nextQuestion: 'example-sister-nextQuestionID',
      },
      Brother: {
        display: 'Brother',
        nextQuestion: 'example-brother-nextQuestionID',
      },
      Grandparent: {
        display: 'Grandparent',
        nextQuestion: 'example-grandparent-nextQuestionID',
      },
      Aunt: {
        display: 'Aunt',
        nextQuestion: 'example-aunt-nextQuestionID',
      },
      Uncle: {
        display: 'Uncle',
        nextQuestion: 'example-uncle-nextQuestionID',
      },
      Cousin: {
        display: 'Cousin',
        nextQuestion: 'example-cousin-nextQuestionID',
      },
      Niece: {
        display: 'Niece',
        nextQuestion: 'example-niece-nextQuestionID',
      },
      Nephew: {
        display: 'Nephew',
        nextQuestion: 'example-nephew-nextQuestionID',
      },
      'God-child': {
        display: 'God-child',
        nextQuestion: 'example-godchild-nextQuestionID',
      },
      'Trusted Friend': {
        display: 'Trusted Friend',
        nextQuestion: 'example-friend-nextQuestionID',
      },
      Other: {
        display: 'Other',
        nextQuestion: 'example-other-nextQuestionID',
      },
    },
    type: 'Relationship',
  },
  keyData: {
    name: {
      firstName: 'example-firstName',
      middleName: 'example-middleName',
      lastName: 'example-lastName',
      suffix: null,
    },
    relationship: 'Father',
  },
};
