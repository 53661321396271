import * as jwt from 'jsonwebtoken';
import { TOKEN } from './constants';

const isTokenExpired = token => {
  const decoded = jwt.decode(token);
  if (decoded === undefined || decoded === null) return true;
  const currentTime = Date.now() / 1000;
  return decoded.exp < currentTime;
};

const isAdmin = token => jwt.decode(token).role === 100;

export const isTokenValid = token => token !== undefined && !isTokenExpired(token);
export const isAdminTokenValid = token => isTokenValid(token) && isAdmin(token);

export const getStoredToken = () => localStorage.getItem(TOKEN);
export const delStoredToken = () => localStorage.setItem(TOKEN, undefined);
