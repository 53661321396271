import React from 'react';
import Flex from './Flex';
import theme from './theme';
import { Icon, Layout, Spin } from 'antd';

const { Content } = Layout;
const loadingIcon = (
  <Icon type="loading" style={{ color: theme.colors.primary, fontSize: 60 }} spin />
);

export const Loader = () => (
  <>
    <Content
      style={{
        backgroundColor: '#ECF6F4',
        display: 'flex',
        padding: '10vh 0',
        justifyContent: 'center',
      }}
    >
      <Flex alignItems="center" justifyContent="center">
        <Spin tip="Loading" size="large" indicator={loadingIcon} />
      </Flex>
    </Content>
  </>
);

export default Loader;
