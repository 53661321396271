import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import styled from 'styled-components';
import { Row, Col, Card, Modal, Icon, notification } from 'antd';
import { Container, Box, Loader, Logo, Heading, Button, Text, theme } from '../components/common';
import { UserContext } from '../contexts/UserContext';
import { getRequest } from '../utils/network';
import { formatQuestion } from '../utils/helpers';
import { questionTypes, displayFuncs } from '../components/questionsTypes/QuestionContainer';

const { confirm } = Modal;

const history = createBrowserHistory({
  forceRefresh: true,
});

// MM20-351 - Add dummy 'Save' button to /review
// this success notification is fake, and will always been successful
// this intentially doesn't do anything other than trigger the notification
// onClick of the 'Save' button
const openFakeSave = type => {
  notification[type]({
    message: 'Saved!',
    description: 'Your documents have successfully been saved.',
    placement: 'topRight',
  });
};

const QuestionHistoryContainer = props => {
  const [isLoading, setLoading] = useState(true);
  const [launched, setLaunched] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [userDatum, setDatum] = useState({});
  const [qHistory, setQHistory] = useState([]);
  const [generated, setGenerated] = useState(false);
  const { datumID } = useParams();

  const fromQuestionare = props.location.search.toLowerCase().includes('questions');

  useEffect(() => {
    if (!launched) {
      if (datumID == undefined) {
        getRequest('/data')
          .then(({ data: respData, data: { data: datum } }) => {
            setLoading(false);
            setUser({
              ...user,
              userData: datum,
            });

            setGenerated(respData.generated != undefined);
            fetchHistory(datum);
            setLaunched(true);
          })
          .catch(err => {
            console.error('Could not pull data from the backend.', err);
          });
      } else {
        getRequest(`/doc_histories/${datumID}`)
          .then(({ data: { data: datum } }) => {
            setLoading(false);
            setGenerated(true);
            fetchHistory(datum);
            setLaunched(true);
          })
          .catch(err => {
            console.error('Could not pull document history from the backend.', err);
          });
      }
    }
  });

  const fetchHistory = datum => {
    setDatum(datum);
    const userHistory = datum.history || [];
    const historyParam = userHistory.join(',');

    getRequest('/history', {
      qids: historyParam,
    }).then(({ data: qHist }) => {
      setLoading(false);
      setQHistory(qHist);
    });
  };

  function qEditConfirm(okAction) {
    confirm({
      icon: <Icon type="warning" theme="twoTone" twoToneColor={theme.colors.red} />,
      title: 'Are you sure?',
      content:
        'Editing this question may require you to answer additional or new questions. Would you like to proceed?',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        okAction();
        // history.push(`/questions/${questionId}`);
        // return new Promise((resolve, reject) => {
        //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        // }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }

  const historyList = () => {
    const userHistory = userDatum.history || [];
    return userHistory.map(item => {
      const histItem = qHistory.find(qItem => qItem.qid === item);
      if (histItem != undefined) {
        const questionStr = formatQuestion(userDatum.responses, histItem.question);
        if (questionStr != undefined && histItem.type !== 'Static') {
          const keyID = histItem.key;
          const QuestionDisplay = displayFuncs[histItem.type];
          const hasResponse = QuestionDisplay == undefined;
          const questionId = histItem.qid;

          const branchIDs = [4, 67, 70, 73, 87, 89];
          const qIdSplit = branchIDs.includes(questionId) || (questionId >= 9 && questionId <= 30);

          const handleClick = e => {
            e.preventDefault();
            const action = () => history.push(`/questions/${questionId}`);
            qIdSplit ? qEditConfirm(action) : action();
          };

          return (
            <Col key={item}>
              <Card
                title={questionStr}
                extra={
                  generated ? null : (
                    <Button minWidth="50px" size="small" type="primary" onClick={handleClick}>
                      Edit
                    </Button>
                  )
                }
                style={{
                  marginTop: 16,
                  backgroundColor: hasResponse ? theme.colors.lightYellow : theme.colors.white,
                }}
              >
                {hasResponse ? (
                  <Text fontWeight="bold">You haven&apos;t responded to this question.</Text>
                ) : (
                  <QuestionDisplay data={userDatum.responses[keyID]} />
                )}
              </Card>
            </Col>
          );
        }
        return null;
      }
    });
  };

  const TextAlt = styled(Text)`
    font-size: 0.75rem;
    text-align: center;

    & {
      ${theme.mediaQueries.md} {
        font-size: 1rem;
      }
    }
  `;

  return (
    <>
      <Box mb={[5, 6]}>
        <Logo height="30px" onClick={() => history.push('/history')} />
        <Container maxWidth={48} px={[4, 3]} mt={[3, 2]} flexDirection="column" flexAlign="start">
          <Row type="flex" justify="center" align="top">
            <Heading>Review Your Answers</Heading>
          </Row>
          <Row type="flex" justify="center" align="top">
            {generated || !fromQuestionare ? (
              <TextAlt mb={[3, 4]}>Review answers provided from your past documents.</TextAlt>
            ) : (
              <>
                <TextAlt>
                  Please review your answers for accuracy and provide answers to any questions
                  highlighted in yellow.
                </TextAlt>
                <TextAlt mb={[3, 4]}>
                  When finished, click “Go to Payment” at the bottom of the screen.
                </TextAlt>
              </>
            )}
          </Row>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Row>{historyList()}</Row>
              <Row type="flex" justify="end">
                <Box my={[3, 4]}>
                  {generated || !fromQuestionare ? (
                    <Button onClick={() => history.push('/history')} type="primary">
                      Go to Your Documents
                    </Button>
                  ) : (
                    // MM20-351 - Add dummy 'Save' button to /review
                    // this 'Save' button is fake and will always trigger success
                    // notification onClick
                    <>
                      <Box maxWidth="325px" mx="auto" my={[2, 3]}>
                        <Row type="flex" justify="space-between">
                          <Col>
                            <Button
                              onClick={() => openFakeSave('success')}
                              type="primary"
                              style={{ marginRight: '1rem' }}
                            >
                              Save
                            </Button>
                          </Col>
                          <Col>
                            <Button onClick={() => history.push('/payment')} type="primary">
                              Go to Payment
                            </Button>
                          </Col>
                        </Row>
                      </Box>
                    </>
                  )}
                </Box>
              </Row>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default QuestionHistoryContainer;
