import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { Layout } from '../components/common';
import PaymentForm from '../components/PaymentForm';
import { STRIPE_PUBLIC_KEY } from '../utils/constants';

class StripeFormContainer extends React.Component {
  constructor() {
    super();
    this.state = { stripe: null };
  }

  componentDidMount() {
    this.setState({
      stripe: window.Stripe(STRIPE_PUBLIC_KEY),
    });
  }

  render() {
    return (
      <>
        <StripeProvider stripe={this.state.stripe}>
          <Elements>
            <PaymentForm />
          </Elements>
        </StripeProvider>
      </>
    );
  }
}

export default StripeFormContainer;
