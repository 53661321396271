const createMediaQuery = n => `@media screen and (min-width:${n}em)`;

const addAliases = (arr, aliases) =>
  aliases.forEach((key, i) =>
    Object.defineProperty(arr, key, {
      enumerable: false,
      get() {
        return this[i];
      },
    }),
  );

const aliases = ['sm', 'md', 'lg', 'xl'];

export const breakpoints = [32, 40, 48, 64];

export const mediaQueries = {
  ...breakpoints.map(createMediaQuery),
  reduceMotion: '@media (prefers-reduced-motion: reduce)',
  reduceTransparency: '@media (prefers-reduced-transparency: reduce)',
};

addAliases(breakpoints, aliases);
addAliases(mediaQueries, aliases);

const fontSizes = ['0.675', '0.825', '1', '1.5', '2.0', '2.5', '3.25', '4.75', '6.25'];

const [xs, sm, base, md, lg, xl, xxl, xxxl, xxxxl] = fontSizes;

const fontWeights = [0, 300, 400, 500, 600, 700, 800];

const letterSpacings = [-0.025, 0, 0.025, 0.5];

const lineHeights = {
  solid: 1,
  title: 1.25,
  copy: 1.5,
};

const space = [0, 4, 8, 16, 32, 64, 128, 256];
const radii = [0, 2, 6];
const radius = '4px';
const width = [0, 16, 32, 64, 128, 256];
const minWidths = [0, 16, 32, 64, 128, 256];
const maxWidths = [0, 16, 32, 64, 128, 256, 512, 768, 1024, 1536];
const heights = [0, 16, 32, 64, 128, 256];
const minHeights = [0, 16, 32, 64, 128, 256];
const maxHeights = [0, 16, 32, 64, 128, 256];
const borders = [0, '1px solid transparent'];
const borderWidths = ['0', '1px', '2px', '4px'];
const shadows = ['0', '0px 2px 4px rgba(0, 0, 0, 0.1)', '0 7px 14px rgba(50,50,93,.1)'];
const opacity = {
  disabled: 0.4,
};

const black = '#000';
const nearBlack = '#111';
const borderGray = '#c0cad5';
const gray = '#4f6f8f';
const darkGray = '#364049';
const darkerGray = '#2f373e';
const midGray = '#555';
const grey = '#CCC';
const silver = '#999';
const lightSilver = '#aaa';
const moonGray = '#ccc';
const lightGray = '#eee';
const white = '#fff';
const nearWhite = '#f4f4f4';
const text = '#001833';
const lightBlue = '#e8f2ff';
const blue = '#007aff';
const darkBlue = '#049';
const blurple = '#4E3FCE';
const violet = '#3259D6';

const lightGreen = '#ecf7ec';
const green = '#0a0';
const darkGreen = '#060';
const lightRed = '#fbebeb';
const red = '#c00';
const darkRed = '#800';
const orange = '#f68013';
const darkOrange = '#f06f20';
const lightPurple = '#f5ebfa';
const purple = '#70b';
const lightYellow = '#fffae5';
const yellow = '#fedc2a';
const transparent = 'transparent';
const blacks = [
  'rgba(0,0,0,.0125)',
  'rgba(0,0,0,.025)',
  'rgba(0,0,0,.05)',
  'rgba(0,0,0,.1)',
  'rgba(0,0,0,.2)',
  'rgba(0,0,0,.3)',
  'rgba(0,0,0,.4)',
  'rgba(0,0,0,.5)',
  'rgba(0,0,0,.6)',
  'rgba(0,0,0,.7)',
  'rgba(0,0,0,.8)',
  'rgba(0,0,0,.9)',
];
const whites = [
  'rgba(255,255,255,.0125)',
  'rgba(255,255,255,.025)',
  'rgba(255,255,255,.05)',
  'rgba(255,255,255,.1)',
  'rgba(255,255,255,.2)',
  'rgba(255,255,255,.3)',
  'rgba(255,255,255,.4)',
  'rgba(255,255,255,.5)',
  'rgba(255,255,255,.6)',
  'rgba(255,255,255,.7)',
  'rgba(255,255,255,.8)',
  'rgba(255,255,255,.9)',
];

const primary = '#F16F6E';
const primaryDark = '#414141';
const secondary = '#204E5F';
const secondaryLight = '#B6D6D7';
const secondaryLighter = '#ECF6F4';
const tertiary = '#ECF6F4';
const accent = '#FFC5A7';
const bgColor = tertiary;
const headingColor = secondary;
const textColor = primaryDark;
const borderColor = 'rgba(65, 65, 65, .125)';
const borderFocusColor = primary;
const placeholderColor = 'rgba(65, 65, 65, .7)';

const fonts = {
  serif: 'athelas, georgia, times, serif',
  sansSerif: '"Lato", -apple-system, sans-serif',
  sansSerifAlt: '"Oxygen", -apple-system, sans-serif',
};

const headingFont = '"Oxygen", -apple-system, sans-serif';
const textFont = '"Lato", -apple-system, sans-serif';

const colors = {
  black,
  nearBlack,
  borderGray,
  gray,
  darkGray,
  darkerGray,
  midGray,
  grey,
  silver,
  lightSilver,
  moonGray,
  lightGray,
  white,
  nearWhite,
  text,
  lightBlue,
  blue,
  darkBlue,
  blurple,
  violet,
  green,
  lightGreen,
  darkGreen,
  red,
  lightRed,
  darkRed,
  orange,
  darkOrange,
  purple,
  lightPurple,
  yellow,
  lightYellow,
  transparent,
  blacks,
  whites,

  // client
  primary,
  primaryDark,
  secondary,
  secondaryLight,
  secondaryLighter,
  tertiary,
  accent,
  bgColor,
  headingColor,
  textColor,
  borderColor,
  borderFocusColor,
  placeholderColor,
};

const theme = {
  breakpoints,
  mediaQueries,
  fontSizes: {
    xs,
    sm,
    md,
    base,
    lg,
    xl,
    xxl,
    xxxl,
    xxxxl,
  },
  fontWeights,
  letterSpacings,
  lineHeights,
  headingFont,
  textFont,
  fonts,
  space,
  radius,
  radii,
  width,
  minWidths,
  maxWidths,
  heights,
  minHeights,
  maxHeights,
  borders,
  borderWidths,
  shadows,
  opacity,
  colors,
  buttons: {
    primary: {
      color: white,
      backgroundColor: primary,
    },
    secondary: {
      color: white,
      backgroundColor: secondaryLighter,
    },
    success: {
      color: 'white',
      backgroundColor: 'green',
    },
    danger: {
      color: 'red',
      backgroundColor: 'white',
    },
  },
  buttonSizes: {
    small: {
      fontSize: '0.75rem',
      minWidth: '0.5rem',
      padding: '0.25rem 0.5rem',
    },
    medium: {
      fontSize: '1rem',
      minWidth: '90px',
      padding: '0.5rem 0.75rem',
      height: '2.5rem',
    },
    large: {
      fontSize: '1.25rem',
      height: '3rem',
      minWidth: '3.5rem',
      padding: '0.5rem 0.75rem',
    },
  },
  textSizes: {
    small: {
      fontSize: '0.875rem',
    },
    medium: {
      fontSize: '1rem',
    },
    large: {
      fontSize: '1.25rem',
    },
  },
};

export default theme;
