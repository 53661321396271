import styled from 'styled-components';
import { color, fontFamily, fontWeight, space } from 'styled-system';
import theme, { mediaQueries } from './theme';

const Subtitle = styled.h1`
    ${color}
    ${space}
    ${fontWeight}
    ${fontFamily}

    & {
      font-size: 1rem;
      letter-spacing: -0.0025rem;
      line-height: 1.4;
      text-align: center;
      width: 100%;
      margin-bottom: 1rem;

      ${theme.mediaQueries.md} {
        font-size: calc(0.75rem + (3-2) * (100vw - 400px)/(900-400));
        margin-top: 0.25rem;
      }
    }
`;

Subtitle.defaultProps = {
  theme,
  fontWeight: 600,
  fontFamily: theme.fonts.sansSerifAlt,
  color: theme.colors.primaryDark,
  mx: 'auto',
};

Subtitle.displayName = 'Subtitle';

export default Subtitle;
