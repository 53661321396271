import styled from 'styled-components';
import PropTypes from 'prop-types';
import Box from './Box';

const Container = styled(Box)`
  max-width: ${props => props.maxWidth}rem;
  width: ${props => props.width};
  flex-grow: ${props => props.flexGrow};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

Container.displayName = 'Container';

Container.propTypes = {
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  flexGrow: PropTypes.number,
};

Container.defaultProps = {
  display: 'flex',
  mx: 'auto',
  flexDirection: 'column',
  width: '100%',
};

export default Container;
