/* eslint-disable react/jsx-max-props-per-line */
import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { createBrowserHistory } from 'history';
import { Form, Input, Result } from 'antd';
import { Button, Container, Box, Subtitle, Heading, Text } from './common';
import { postRequest } from '../utils/network';
import { BASE_URL } from '../utils/constants';

const history = createBrowserHistory({
  forceRefresh: true,
});

const ForgotPassword = props => {
  const {
    form,
    form: { getFieldDecorator },
  } = props;
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit = () => {
    setError('');
    form.validateFields((err, data) => {
      if (err) {
        return;
      }
      postRequest(`${BASE_URL}/request_recovery`, data)
        .then(resp => {
          setSuccess(true);
        })
        .catch(error => {
          setError('An error occurred while requesting a password reset. Please try again.');
        });
    });
  };

  const errorMessage = {
    color: 'red',
    fontSize: '0.875rem',
    marginTop: '1rem',
  };

  const resetForm = () => (
    <>
      <Subtitle>Reset Password</Subtitle>
      <Text style={{ marginBottom: '1rem' }}>
        Add your email below and we will send a link to reset your password.
      </Text>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Email is required' },
              { type: 'email', message: 'Must be an email address' },
            ],
          })(
            <Input size="large" type="email" placeholder="Email" ref={register({})} hasFeedback />,
          )}
        </Form.Item>
        <Button style={{ width: '100%' }} htmlType="submit">
          Reset Password
        </Button>
      </Form>
      <Text style={errorMessage}>{error}</Text>
    </>
  );

  const successDisplay = () => (
    <Result status="success" title="Check your email for instructions to set your new password." />
  );

  return (
    <Container width={1} maxWidth={35} px={[3, 4]} py={[1, 2]}>
      {success ? successDisplay() : resetForm()}
    </Container>
  );
};

export default Form.create()(ForgotPassword);
