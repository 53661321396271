import React from 'react';
import { QuestionPropTypes } from './QuestionPropTypes';
import { Form } from 'antd';
import { Button, Box, Flex, Container, Heading } from '../common';
import PrevNextBtnGroup from '../PrevNextBtnGroup';

const staticContainer = {
  margin: 'auto',
  marginTop: '1.5rem',
};

function FinalStaticTypeQuestion(props) {
  const { question, keyData, onSubmit, handlePrevious } = props;

  const handleSubmit = ev => {
    ev.preventDefault();
    onSubmit({
      question,
      keyData,
      type: 'final_static',
    });
  };

  return (
    <>
      <Container width={1} px={[3, 5]} mt={[1, 2]}>
        <Box mt={[4, 5]}>
          <Heading style={{ maxWidth: '900px', textAlign: 'left' }}>{question.question}</Heading>
        </Box>
      </Container>
      <Form>
        <PrevNextBtnGroup
          handlePrevious={handlePrevious}
          onSubmit={handleSubmit}
          currentQuestion={question.questionID}
        />
      </Form>
    </>
  );
}

FinalStaticTypeQuestion.propTypes = {
  ...QuestionPropTypes,
  // keyData: PropTypes.objectOf(
  //   PropTypes.shape({
  //     body: PropTypes.string,
  //     buttonText: PropTypes.string,
  //   }),
  // ).isRequired,
};

export default FinalStaticTypeQuestion;

export const exampleFinalStaticTypeQuestion = {
  question: {
    ID: 'example-finalstatictype-id',
    questionID: 1,
    question: 'Title​',
    nextQuestionID: 0,
    keyID: 'example-finalstatictype-keyID',
    select: null,
    type: 'FinalStatic',
  },
  keyData: {
    body:
      'Example: You have finished the Responsible People Section. In this last section you will decide who receives your property.​ \n\nYou can leave your property to @Testatorfirstname and your children.​ \nYou can leave specific amounts of money to organizations, charities or individuals other than @testatorfirstname and your children. \nYou can leave specific items of personal property (see sidebar) to specific individuals in the memorandum attached to the end of your will.',
    buttonText: 'Next Question',
  },
};
