import React, { useRef, useState } from 'react';
import { Row, Col, Form, Input, notification, Icon } from 'antd';
import { Button, Text, theme } from '.';

const FormItem = Form.Item;

export default function CopyToClipboard() {
  const refCode = useRef(null);
  const [copy] = useState('');

  function copyToClipboard(e) {
    refCode.current.select();
    document.execCommand('copy');
    e.target.focus();

    notification.open({
      message: <Text>Copied to clipboard</Text>,
      icon: <Icon type="check-circle" theme="twoTone" twoToneColor={theme.colors.green} />,
    });
  }

  return (
    <>
      <Form>
        <FormItem className="form__item-label">
          <Input size="large" type="text" name="referrer_code" ref={refCode} readonly />
        </FormItem>
        {document.queryCommandSupported('copy') && (
          <Button width={1} type="primary" onClick={copyToClipboard}>
            Copy Code
          </Button>
        )}
      </Form>
    </>
  );
}
