import React, { useState, useEffect } from 'react';
import { Box, Container, Flex, Heading } from './common';
import { Table } from 'antd';
import { getRequest } from '../utils/network';
import { Coins } from 'styled-icons/fa-solid';

var moment = require('moment');

const pageSize = 20;

const RefCodeList = props => {
  const [codes, setCodes] = useState([]);
  const [total_codes, setTotalCodes] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const [launched, setLaunched] = useState(false);

  useEffect(() => {
    if (!launched) {
      fetchCodes(0);
    }
  });

  const fetchCodes = page => {
    getRequest('/referral_codes', {
      page,
      per_page: pageSize,
    }).then(({ data: respData }) => {
      setCodes(respData);
      const paginationUpdate = { ...pagination };
      paginationUpdate.current = page;
      paginationUpdate.total = respData.count;
      setPagination(paginationUpdate);
      setTotalCodes(respData.length);
      setLaunched(true);
    });
  };

  var companyRefs = {};

  var total_uses = 0;
  codes.forEach(code => {
    total_uses += code.uses;
  });

  codes.forEach(function(code) {
    if (companyRefs.hasOwnProperty(code.id)) {
      companyRefs[code.id] = companyRefs[code.id] + code.uses;
    } else {
      companyRefs[code.id] = code.uses;
    }
  });

  var companyRefUses = [];

  for (var prop in companyRefs) {
    companyRefUses.push({ id: prop, uses: companyRefs[prop] });
  }

  const joinedDisplay = time => `${moment(time).format('MMMM DD, YYYY')}`;

  const handleChange = (p, filters, sorter) => {
    fetchCodes(p.current);
  };

  const columns = [
    {
      title: 'Referral Code',
      dataIndex: 'code',
    },
    {
      title: 'Times Used',
      dataIndex: 'uses',
      key: 'uses',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.uses - b.uses,
    },
    {
      title: 'Joined',
      dataIndex: 'created_at',
      render: text => joinedDisplay(text),
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
  ];

  const companyCols = [
    {
      title: 'Organization',
      dataIndex: 'owner',
      render: owner => (owner != null ? owner : 'Organization Name'),
    },
    {
      title: 'Referrals',
      dataIndex: 'uses',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.uses - b.uses,
    },
  ];

  const countNum = {
    fontSize: '3rem',
    fontWeight: '700',
    lineHeight: 1.2,
    textAlign: 'center',
  };

  const countTitle = {
    fontSize: '0.9rem',
    textTransform: 'uppercase',
    fontWeight: '600',
    width: '100%',
  };

  return (
    <>
      <Heading>Referral Codes</Heading>
      <Container maxWidth={72} px={[2, 3, 4]} mb={[2, 3]} flexDirection="column" flexAlign="start">
        <Box p={[2, 3, 4]}>
          <Flex justifyContent="center" style={countNum}>{`${total_uses}`}</Flex>
          <Flex justifyContent="center" style={countTitle}>
            Redeemed Codes
          </Flex>
        </Box>
      </Container>
      <Container maxWidth={72} p={[2, 3, 4]} mb={[2, 3]} flexDirection="column" flexAlign="start">
        <Table
          columns={companyCols}
          dataSource={companyRefUses}
          onChange={handleChange}
          pagination={{ defaultPageSize: 10 }}
          rowKey={code => code.id}
        />
      </Container>
      <Container maxWidth={72} p={[2, 3, 4]} mb={[2, 3]} flexDirection="column" flexAlign="start">
        <Table
          columns={columns}
          dataSource={codes}
          pagination={{ defaultPageSize: 10 }}
          onChange={handleChange}
          rowKey={code => code.id}
        />
      </Container>
    </>
  );
};

export default RefCodeList;
