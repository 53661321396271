import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import useForm from 'react-hook-form';
import { Alert, Form, Input } from 'antd';
import { Box, Button, Subtitle, Container, Link, theme } from './common';
import { postRequest } from '../utils/network';
import { UserContext } from '../contexts/UserContext';
import { isTokenValid, isAdminTokenValid, getStoredToken } from '../utils/auth';
import { TOKEN } from '../utils/constants';
import { createBrowserHistory } from 'history';
import useLocalStorage from '../hooks/useLocalStorage';

const history = createBrowserHistory({
  forceRefresh: true,
});

const initialState = {
  id: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  email: '',
  postal_code: '',
};

const Login = props => {
  const [user, setUser] = useContext(UserContext);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', initialState);
  const [error, setError] = useState('');
  const { register, handleSubmit } = useForm();
  const { form, form: { getFieldDecorator } } = props;

  const onSubmit = data => {
    setError('');
    form.validateFields((err, data) => {
      if (err) {
        return;
      }
      postRequest('/signin', data, {})
        .then(response => {
          const token = response.headers[TOKEN];
          let loggedIn = false;

          if (isTokenValid(token)) {
            localStorage.setItem(TOKEN, token);
            setCurrentUser(response.data);
            loggedIn = true;
            let path = '/history';
            if (isAdminTokenValid(getStoredToken())) {
              path = '/admin';
            }
            history.push(path);
          }

          setUser({ ...user, loggedIn });
        })
        .catch(error => {
          setError('An error occurred attempting to login to your account. Please try again.');
        });
    });
  };

  // if (user.loggedIn) {
  //   let path = '/history';
  //   // check for admin
  //   if (isAdminTokenValid(getStoredToken())) {
  //     path = '/admin';
  //   }
  //   return <Redirect to={{ pathname: path }} />;
  // }

  const resetPasswordLink = {
    textAlign: 'right',
    fontSize: '14px',
    float: 'right',
    color: theme.colors.primaryDark,
    fontWeight: 600,
  };

  return (
    <Container maxWidth={28} px={[2, 3, 4]} flexDirection="column">
      <Subtitle>Login</Subtitle>
      <Box mb={2}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please enter your Email' }],
            })(<Input size="large" type="text" placeholder="Email" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please enter your Password' }],
            })(<Input size="large" type="password" placeholder="Password" />)}
          </Form.Item>
          <Button style={{ width: '100%' }} htmlType="submit">
            Sign In
          </Button>
        </Form>
      </Box>
      <Box style={{ marginLeft: '50%' }}>
        <Link style={resetPasswordLink} href="/password-reset">
          Forgot Password?
        </Link>
      </Box>
      <Box mt={[2, 3]}>
        {error === '' ? null : (
          <Alert message="Login Error" description={error} type="error" showIcon />
        )}
      </Box>
    </Container>
  );
};

export default Form.create()(Login);
