import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Form, Input, Radio, Row } from 'antd';
import { Box, Button, Container, Text, Flex } from '../common';
import { QuestionTypePropTypes, QuestionTypeDefaultProps } from './QuestionPropTypes';
import PrevNextBtnGroup from '../PrevNextBtnGroup';

const SelectTypeQuestion = props => {
  const {
    question,
    keyData,
    onSubmit,
    form,
    form: { getFieldDecorator },
    handlePrevious,
  } = props;

  const prepData = data => {
    const selectedOption = question.select[data.select] || {};
    const nextQuestionID =
      parseInt(selectedOption.nextQuestion, undefined) || question.nextQuestionID;

    const preppedQuestion = {
      ...question,
      nextQuestionID,
    };

    return {
      question: preppedQuestion,
      response: data,
    };
  };

  useEffect(() => {
    form.resetFields();
  }, [question.questionID]);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(prepData(values));
      }
    });
  };

  const radioOptionsKeys = () => {
    const keys = Object.keys(question.select);
    // if yes/no question
    if (
      keys.length === 2 &&
      (keys[0] === 'No' || keys[1] == 'No') &&
      (keys[1] === 'Yes' || keys[1] === 'Yes')
    ) {
      return ['Yes', 'No'];
    }
    return keys;
  };

  const radioOptions = () => {
    const options = question.select;
    return radioOptionsKeys().map(k => (
      <Radio key={k} value={k}>
        {options[k].display}
      </Radio>
    ));
  };

  const radioGroup = () => (
    <Box my={[4, 5]}>
      <Row type="flex" justify="center">
        <Form.Item>
          {getFieldDecorator('select', { initialValue: keyData.select })(
            <Radio.Group size="large">{radioOptions()}</Radio.Group>,
          )}
        </Form.Item>
      </Row>
    </Box>
  );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box my={[4, 5]}>
          {Object.keys(question.select).length <= 2 ? (
            radioGroup()
          ) : (
            <SelectField
              optionList={question.select}
              defaultValue={keyData.select}
              form={form}
              questionKey={question.keyID}
              isRequired
            />
          )}
        </Box>
        <PrevNextBtnGroup handlePrevious={handlePrevious} />
      </Form>
    </>
  );
};

export const SelectTypeDisplay = props => {
  const {
    data: { select },
  } = props;
  return select;
};

const empty = str => str === undefined || str.length === 0;
const fieldName = (prefix = '') => (empty(prefix) ? 'select' : `${prefix}-select`);
const otherTextName = (prefix = '') => `${fieldName(prefix)}-other`;

export const selectFieldValue = (data = {}, namePrefix = '') =>
  data[fieldName(namePrefix)].toLowerCase() === 'other'
    ? data[otherTextName(namePrefix)]
    : data[fieldName(namePrefix)];

export const SelectField = props => {
  const {
    optionList,
    defaultValue = '',
    namePrefix,
    onChange,
    emptyDisplay,
    questionKey,
    form,
    form: { getFieldDecorator },
    isRequired,
  } = props;
  const [showOther, setShowOther] = useState(false);

  const baseOptionList = {
    ...optionList,
  };

  useEffect(() => {
    form.resetFields([fieldName(namePrefix)]);
  }, [defaultValue]);

  const sortRelationshipStatus = () => {
    const orderedStatuses = [
      'Single',
      'Engaged',
      'Married',
      'Domestic partnership',
      'Civil union',
      'Divorced',
      'Widowed',
    ];
    return orderedStatuses.map(status => {
      const opt = baseOptionList[status];
      return (
        <Select.Option key={status} value={status}>
          {opt.display}
        </Select.Option>
      );
    });
  };

  const options = () => {
    // single question reordering here, as the list appears to come in alphabetically
    if (questionKey === 'relationship_status') {
      return sortRelationshipStatus();
    }
    return Object.keys(baseOptionList).map(opt => {
      return (
        <Select.Option key={opt} value={opt}>
          {baseOptionList[opt].display}
        </Select.Option>
      );
    });
  };

  const handleChange = value => {
    if (value == undefined) {
      setShowOther(false);
      return;
    }

    setShowOther(value.toLowerCase() === 'other');
    if (onChange != undefined) {
      onChange(value, fieldName(namePrefix));
    }
  };

  const selectDefaultValue = Object.keys(optionList).includes(defaultValue)
    ? defaultValue
    : undefined;

  return (
    <>
      <Row type="flex" justify="center">
        <Form.Item>
          {getFieldDecorator(fieldName(namePrefix), {
            initialValue: selectDefaultValue,
            rules: [{ required: isRequired, message: 'Please make a selection' }],
          })(
            <Select
              size="large"
              placeholder={emptyDisplay || 'Select Option'}
              onChange={handleChange}
              allowClear={!isRequired}
            >
              {options()}
            </Select>,
          )}
        </Form.Item>
        {showOther ? (
          <Form.Item>
            {getFieldDecorator(otherTextName(namePrefix), {
              initialValue: defaultValue,
            })(<Input size="large" type="text" />)}
          </Form.Item>
        ) : null}
      </Row>
      {questionKey === 'relationship_status' ? (
        <Row type="flex" justify="center">
          <Text fontSize="0.675rem" style={{ maxWidth: '325px', margin: '0 auto 2rem' }} mb={2}>
            * If you select Married, Engaged, Domestic Parnetship, or Civil Union, please have your
            partner available to answer questions with you.
          </Text>
        </Row>
      ) : null}
    </>
  );
};

SelectField.propTypes = {
  optionList: PropTypes.objectOf(
    PropTypes.shape({
      display: PropTypes.string,
      nextQuestion: PropTypes.string,
    }),
  ).isRequired,
  defaultValue: PropTypes.string,
  namePrefix: PropTypes.string,
  onChange: PropTypes.func,
  emptyDisplay: PropTypes.string,
  isRequired: PropTypes.bool,
};

SelectField.defaultProps = {
  defaultValue: '',
  namePrefix: '',
  onChange: null,
  emptyDisplay: 'Select Option',
  isRequired: false,
};

SelectTypeQuestion.propTypes = {
  ...QuestionTypePropTypes,
  keyData: PropTypes.shape({
    select: PropTypes.string,
  }).isRequired,
};

SelectTypeQuestion.defaultProps = QuestionTypeDefaultProps;

export default Form.create()(SelectTypeQuestion);

export const exampleSelectTypeQuestion = {
  question: {
    ID: 'example-selecttype-id',
    questionID: 1,
    question: 'Do you like bananas?',
    nextQuestionID: 1,
    keyID: 'example-selecttype-keyID',
    select: {
      Yes: {
        display: 'Yes',
        nextQuestion: 2,
      },
      No: {
        display: 'No',
        nextQuestion: 3,
      },
    },
    type: 'Select',
  },
  keyData: {
    select: 'Yes',
  },
};
