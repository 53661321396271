import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber, Row } from 'antd';
import { Box, Button, Flex } from '../common';
import { QuestionTypePropTypes, QuestionTypeDefaultProps } from './QuestionPropTypes';
import PrevNextBtnGroup from '../PrevNextBtnGroup';

function CountTypeQuestion(props) {
  const {
    question,
    keyData: { count },
    onSubmit,
    form,
    form: { getFieldDecorator },
    handlePrevious,
  } = props;

  const prepData = data => {
    return {
      question,
      response: data,
    };
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(prepData(values));
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Box my={[4, 5]}>
        <Row type="flex" justify="center">
          <Form.Item>
            {getFieldDecorator('count', {
              initialValue: count || 1,
              rules: [{ required: true, message: 'Number is required' }],
            })(<InputNumber size="large" min={1} />)}
          </Form.Item>
        </Row>
      </Box>
      <PrevNextBtnGroup handlePrevious={handlePrevious} />
    </Form>
  );
}

export const CountTypeDisplay = props => {
  const {
    data: { count },
  } = props;
  return count;
};

const CountKeyPropTypes = PropTypes.shape({
  count: PropTypes.number,
});

CountTypeQuestion.propTypes = {
  ...QuestionTypePropTypes,
  keyData: PropTypes.objectOf(CountKeyPropTypes.isRequired).isRequired,
};

CountTypeQuestion.defaultProps = QuestionTypeDefaultProps;

export default Form.create()(CountTypeQuestion);

export const exampleCountTypeQuestion = {
  question: {
    ID: 'example-counttype-id',
    questionID: 1,
    question: 'How many children do you have?',
    nextQuestionID: 2,
    keyID: 'example-counttype-keyID',
    select: null,
    type: 'Count',
  },
  keyData: {
    count: 12,
  },
};
