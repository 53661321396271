import React from 'react';
import styled from 'styled-components';

import Button, { StyledButton, ButtonBody } from './Button';

// outline styles
const StyledSolidButton = styled(StyledButton)`
  & {
    position: relative;
    z-index: 0;
    transition: all 0.15s ease;
  }
  &:hover {
    text-decoration: inherit;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  &:hover::before {
    opacity: 0.9;
  }
  &:active {
    box-shadow: none;
    background: #000;
  }
`;

const SolidButton = React.forwardRef(({ children, icon, iconpos, ...props }, ref) => {
  return (
    <StyledSolidButton {...props} ref={ref}>
      <ButtonBody icon={icon} iconpos={iconpos} children={children} />
    </StyledSolidButton>
  );
});

SolidButton.defaultProps = {
  ...Button.defaultProps,
  borderRadius: 1,
  boxShadow: 1,
};

export { StyledSolidButton };

export default SolidButton;
