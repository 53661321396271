import React, { Component } from 'react';
import { Card, Alert, Spin, Icon } from 'antd';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { createBrowserHistory } from 'history';
import { networkInterfaces } from 'os';
import { Result } from 'antd';
import { Container, Box, Flex, Button, Heading, Subtitle, Text, Theme } from './common';
import { BASE_URL } from '../utils/constants';
import { getRequest, postRequest } from '../utils/network';

const history = createBrowserHistory({
  forceRefresh: true,
});

class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = { complete: false, price: -1, error: '', loading: false };
    this.submit = this.submit.bind(this);
    this.getPrice();
  }

  async getPrice() {
    const priceResp = await getRequest('/payments/price');
    this.setState({ price: priceResp.data.price });
    console.log(priceResp.data.price);
  }

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async submit(ev) {
    this.setState({ error: '', loading: true });
    const { source } = await this.props.stripe.createSource({
      type: 'card',
      owner: { name: 'Name' },
    });
    if (source == undefined || source.id == undefined) {
      this.setState({ error: 'Error processing credit card.', loading: false });
      return;
    }

    const response = await postRequest(`${BASE_URL}/payments/purchase`, {
      card_id: source.id,
    })
      .then(response => {
        this.setState({ loading: false });
        if (response.status < 300) this.setState({ complete: true });
        console.log(response);
      })
      .catch(error => {
        if (error.response.data.error.length > 0)
          this.setState({ error: error.response.data.error });
        console.error(error.response);
      });
  }

  render() {
    const { complete, price, error, loading } = this.state;
    const loadingIcon = <Icon type="loading" style={{ fontSize: 48 }} spin />;
    const successContent = [
      'Congratulations! You did it! How good does this feel!',
      'Your Complete Will Package is on its way to your inbox. The package is attached to an email that contains instructions for your next steps.',
      'Delivery can take up to 5 minutes, so sit back and enjoy this accomplishment for a few minutes. You earned it.',
    ];

    if (complete)
      return (
        <>
          <Box maxWidth="28rem" py={[2, 3]} width={1} m="auto">
            <Result
              status="success"
              title="Payment Successsul"
              subTitle={successContent.join('\n\n')}
              extra={[
                <Text style={{ marginBottom: '1.5rem', textAlign: 'center' }}>
                  Welcome to Willingworth!
                </Text>,
                <Button type="primary" key="history" onClick={() => history.push('/history')}>
                  View Document History
                </Button>,
              ]}
            />
          </Box>
        </>
      );

    const errorMessage = {
      color: 'red',
      fontSize: '0.875rem',
      textAlign: 'center',
    };

    const isLoading = () => (
      <>
        <Flex alignItems="center" justifyContent="center">
          <Spin tip="Processing Payment" size="large" indicator={loadingIcon} />
        </Flex>
      </>
    );

    const paymentCopy = () => (
      <Text style={{ marginBottom: '1.5rem', textAlign: 'center' }}>
        You’re one step away from protection you can trust! Please enter your credit card
        information to complete the payment process. You will then receive an email containing your
        Complete Will Package and signing instructions.
      </Text>
    );

    const paymentAmount = () =>
      price != -1 ? (
        <>
          {paymentCopy()}
          <Subtitle>Submit Payment</Subtitle>
          <Heading>
            Price = $
            {(price / 100).toFixed(2)}
          </Heading>
        </>
      ) : null;

    const stripeForm = () => (
      <>
        {loading ? isLoading() : paymentAmount()}
        <Box my={[1, 2]} width={1}>
          <CardElement />
          <Box pt={[1, 2]}>
            <p style={errorMessage}>{error}</p>
          </Box>
          <Button style={{ width: '100%' }} type="primary" onClick={this.submit}>
            Pay {price != -1 ? `$${(price / 100).toFixed(2)}` : null}
          </Button>
        </Box>
      </>
    );

    return (
      <>
        <Container maxWidth={28} px={[3, 2]} py={[4, 5]}>
          {stripeForm()}
        </Container>
      </>
    );
  }
}

export default injectStripe(PaymentForm);
