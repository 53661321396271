import React, { useContext, useEffect, useState } from 'react';
import FileSaver, { saveAs } from 'file-saver';
import { createBrowserHistory } from 'history';
import { Row, Col, Icon, Tooltip, Table, Divider, Modal } from 'antd';
import { Container, Box, Button, Title, Subtitle, theme, Link, Loader } from '../components/common';
import { UserContext } from '../contexts/UserContext';
import { getRequest, postRequest } from '../utils/network';
const moment = require('moment');

const { confirm } = Modal;

const history = createBrowserHistory({
  forceRefresh: true,
});

const HistoryContainer = props => {
  const [isLoading, setLoading] = useState(true);
  const [launched, setLaunched] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [qHistory, setQHistory] = useState([]);
  const [docHistory, setDocHistory] = useState([]);

  useEffect(() => {
    if (!launched) {
      getRequest('/data').then(({ data: respData }) => {
        const userData = respData.data;
        setUser({
          ...user,
          userData,
        });
        setLaunched(true);
        const userHistory = userData.history || [];
        const historyParam = userHistory.join(',');

        getRequest('/history', {
          qids: historyParam,
        }).then(({ data: respData }) => {
          setQHistory(respData);
        });
        getRequest('/doc_histories')
          .then(({ data: doc_histories }) => {
            console.log('got result:', doc_histories);
            setLoading(false);
            setDocHistory(
              doc_histories.map(dh => {
                return { ...dh, key: dh.id };
              }),
            );
          })
          .catch(err => {
            console.error('Could not pull document history from the backend.', err);
          });
      });
    }
  });

  const docStyles = {
    textAlign: 'left',
  };

  const linkStyles = {
    color: '#414141',
  };

  const columns = [
    {
      title: 'Date Generated',
      dataIndex: 'created_at',
      key: 'created_at',
      render: created_at => `${moment(created_at).format('MMM DD, YYYY')}`,
    },
    {
      title: 'Originally Sent to',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <a style={linkStyles} href={`/review/${record.id}`}>
            Review
          </a>
          <Divider type="vertical" />
          <a
            style={linkStyles}
            onClick={e => {
              e.preventDefault();
              console.log('send:', record);
              send_doc_history(record);
            }}
          >
            Send
          </a>
        </span>
      ),
    },
  ];

  const email_docs = e => {
    e.preventDefault();

    // Fetch the dynamically generated zip file from the server.
    getRequest('/email_docs', {})
      .then(response => {
        console.log('email docs success:', response);
        alert(
          'Your documents have been sent to the email connected to this account. Please reach out to support@willingworth.com if you do not receive them within 24 hours.',
        );
      })
      .catch(err => {
        console.error('Could not Download the Zip from the backend.', err);
      });
  };

  const get_doc_history = doc_hist => {
    getRequest(`/doc_histories/${doc_hist.id}`, {})
      .then(response => {
        setLoading(false);
        console.log('now send it to review with this data:', response);
      })
      .catch(err => {
        console.error('Could not get doc_history from the backend.', err);
      });
  };

  const send_doc_history = doc_hist => {
    // Fetch the dynamically generated zip file from the server.
    getRequest(`/doc_histories/${doc_hist.id}/send`, {})
      .then(response => {
        setLoading(false);
        console.log('docs sent!');
        alert(
          'Your documents have been sent to the email connected to this account. Please reach out to support@willingworth.com if you do not receive them within 24 hours.',
        );
      })
      .catch(err => {
        console.error('Could not get doc_history from the backend.', err);
      });
  };

  const reset_questions = e => {
    e.preventDefault();
    confirm({
      icon: <Icon type="warning" theme="twoTone" twoToneColor={theme.colors.red} />,
      title: 'Are you sure?',
      content:
        'Starting a new questionnaire will delete any current questionnaire in progress. Would you like to proceed?',
      okText: 'Proceed',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        // Post an empty data set to the server.
        postRequest('/data', {})
          .then(response => {
            console.log('data clear success:', response);
            setUser({
              ...user,
              userData: {},
            });
            history.push('/questions');
          })
          .catch(err => {
            console.error('Could not erase data from the backend.', err);
          });
      },
      onCancel() {},
    });
  };

  const docs = e => {
    e.preventDefault();

    // Fetch the dynamically generated zip file from the server.
    getRequest('/docs.zip', {}, 'blob')
      .then(response => {
        // Log somewhat to show that the browser actually exposes the custom HTTP header
        const fileNameHeader = 'x-suggested-filename';
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName = suggestedFileName === undefined ? 'docs.zip' : suggestedFileName;
        console.log(
          'Received header [' +
            fileNameHeader +
            ']: ' +
            suggestedFileName +
            ', effective fileName: ' +
            effectiveFileName,
        );

        // Let the user save the file.
        saveAs(response.data, effectiveFileName);
      })
      .catch(err => {
        console.error('Could not Download the Zip from the backend.', err);
      });
  };

  const newQuestionnaire = () => (
    <>
      <Box width="100%" py={[3, 4]}>
        <Row gutter={[40, 8]}>
          <Col md={24} lg={21}>
            <Subtitle style={docStyles}>
              <span style={{ marginRight: '8px' }}>Start New Questionnaire</span>
              <Tooltip title="Start a new questionnaire from scratch here! NOTE: A questionnaire in progress will be deleted.">
                <Icon type="info-circle" />
              </Tooltip>
            </Subtitle>
          </Col>
          <Col md={24} lg={3}>
            <Button className="history__btn" type="primary" onClick={reset_questions}>
              Start New
            </Button>
          </Col>
        </Row>
      </Box>
    </>
  );

  const inProgressQuestionnaire = () => (
    <>
      <Box width="100%" py={[3, 4]}>
        <Row gutter={[40, 8]}>
          <Col md={24} lg={21}>
            <Subtitle style={docStyles}>
              <span style={{ marginRight: '8px' }}>Questionnaire In-Progress</span>
              <Tooltip title="This is a questionnaire you have not yet completed. Proceed with the questionnaire to receive your documents.">
                <Icon type="info-circle" />
              </Tooltip>
            </Subtitle>
          </Col>
          <Col md={24} lg={3}>
            <Link href="/questions">
              <Button className="history__btn" type="primary">
                Edit
              </Button>
            </Link>
          </Col>
        </Row>
      </Box>
    </>
  );

  const currentDocuments = () => (
    <>
      <Box width="100%" py={[3, 4]}>
        <Row gutter={[40, 8]}>
          <Col md={24} lg={18}>
            <Subtitle style={docStyles}>
              <span style={{ marginRight: '8px' }}>Current Documents</span>
              <Tooltip title="These are your most up-to-date documents. Please make sure to review the instructions packet in your email to and complete the steps to finalize your documents.">
                <Icon type="info-circle" />
              </Tooltip>
            </Subtitle>
          </Col>
          <Col md={24} lg={3}>
            <Link href="/review">
              <Button className="history__btn" type="primary">
                Review
              </Button>
            </Link>
          </Col>
          <Col md={24} lg={3}>
            {' '}
            <Button className="history__btn" type="primary" onClick={email_docs}>
              Send
            </Button>
          </Col>
        </Row>
      </Box>
    </>
  );

  const documentHistory = () => (
    <>
      <Box width="100%" py={[3, 4]}>
        <Row gutter={[40, 8]}>
          <Col span={24}>
            <Subtitle style={docStyles}>
              <span style={{ marginRight: '8px' }}>History</span>
              <Tooltip title="These are your past documents. PLEASE NOTE: If you have correctly completed the instructions in your current documents these documents are no longer valid.">
                <Icon type="info-circle" />
              </Tooltip>
            </Subtitle>
          </Col>
        </Row>
      </Box>
      <Box width="100%" py={[3, 4]}>
        <Row>
          <Col span={24}>
            <Table columns={columns} dataSource={docHistory} />
          </Col>
        </Row>
      </Box>
    </>
  );

  return (
    <>
      <Container
        maxWidth={72}
        px={[2, 3, 4]}
        mt={[5, 2]}
        mb={[4, 2]}
        flexDirection="column"
        flexAlign="spaceBetween"
      >
        {isLoading ? (
          <Loader style={{ height: '80vh' }} />
        ) : !isLoading ? (
          <>
            <Title>Your Documents</Title>
            {newQuestionnaire()}
            {inProgressQuestionnaire()}

            {docHistory.length ? (
              <>
                {currentDocuments()}
                {documentHistory()}
              </>
            ) : (
              <Box height="30vh" />
            )}
          </>
        ) : null}
      </Container>
    </>
  );
};

export default HistoryContainer;
