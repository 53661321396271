import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { DateFields, NameBirthdateKeyPropTypes } from './NameBirthdateType';
import { QuestionTypePropTypes, QuestionTypeDefaultProps } from './QuestionPropTypes';
import { Box, Button } from '../common';
import { safeParseInt } from '../../utils/helpers';
import PrevNextBtnGroup from '../PrevNextBtnGroup';
var moment = require('moment');
const minAge = 18;

const DateTypeQuestion = props => {
  const { question, keyData, onSubmit, form, handlePrevious } = props;

  const prepData = data => {
    const year = data['year-select'];
    const month = data['month-select'];
    const day = data['day-select'];
    const dateData = [safeParseInt(year), safeParseInt(month), safeParseInt(day)];
    const date = new Date(...dateData);
    return {
      question,
      response: { date },
    };
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const data = prepData(values);
        const date = data.response.date;
        const momentDate = moment(date);
        const yearsAgo = moment().subtract(minAge, 'years');
        if (momentDate.isAfter(yearsAgo)) {
          form.setFields({
            'year-select': {
              value: values['year-select'],
              errors: [new Error(`Must be at least ${minAge} years old to continue.`)],
            },
          });
          return;
        }
        onSubmit(data);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} layout="inline">
      <Box my={[4, 5]}>
        <DateFields date={keyData.date} form={form} />
      </Box>
      <PrevNextBtnGroup handlePrevious={handlePrevious} />
    </Form>
  );
};

export const DateTypeDisplay = props => {
  const {
    data: { date },
  } = props;

  return moment(date).format('MMMM DD, YYYY');
};

const DateKeyPropTypes = PropTypes.shape({
  // date: PropTypes.instanceOf(Date),
  date: PropTypes.string,
});

DateTypeQuestion.propTypes = {
  ...QuestionTypePropTypes,
  keyData: PropTypes.objectOf(DateKeyPropTypes.isRequired).isRequired,
};

DateTypeQuestion.defaultProps = QuestionTypeDefaultProps;

export default Form.create()(DateTypeQuestion);

export const exampleDateTypeQuestion = {
  question: {
    ID: 'example-datetype-id',
    questionID: 1,
    question: 'When were you born?',
    nextQuestionID: 2,
    keyID: 'example-datetype-keyID',
    select: null,
    type: 'Date',
  },
  keyData: {
    date: new Date(),
  },
};
