import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import { Input } from 'antd';
import { NameFields, NameKeyPropTypes } from './NameType';
import { SelectField } from './SelectType';
import { Button } from '../common';
import { relationshipMap, relationshipTypes, relationshipMapStatus } from '../../utils/constants';
import { safeParseFloat, relationshipNameKey } from '../../utils/helpers';
import { QuestionPropTypes } from './QuestionPropTypes';
import { UserContext, userRelationshipMap } from '../../contexts/UserContext';
import PrevNextBtnGroup from '../PrevNextBtnGroup';

function RelationshipAmountTypeQuestion(props) {
  const { register, handleSubmit, errors } = useForm();
  const [user, setUser] = useContext(UserContext);
  const { question, keyData, onSubmit, handlePrevious } = props;

  const rel = keyData;

  const prepData = data => {
    const response = {
      name: {
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        suffix: data.suffix,
      },
      amount: safeParseFloat(data.amount),
      relationship: data.select,
    };

    return {
      question,
      response,
      relationships: [
        {
          relationship: response.relationship,
          name: response.name,
          key: relationshipNameKey(response.name, response.relationship),
        },
      ],
    };
  };

  const submitHandler = data => {
    onSubmit(prepData(data));
  };

  const relationshipOptions = {
    ...userRelationshipMap(user.userData.relationships),
    ...relationshipMapStatus(user.userData.responses['relationship_status']),
  };

  return (
    <>
      <NameFields register={register} errors={errors} questionValues={rel.name} />
      <br />
      <SelectField
        register={register}
        defaultValue={rel.relationship}
        optionList={relationshipOptions}
        isRequired
      />
      <Input
        size="large"
        ref={register}
        type="text"
        defaultValue={rel.amount}
        name="amount"
        placeholder="Amount"
      />
      <br />
      <PrevNextBtnGroup handlePrevious={handlePrevious} />
    </>
  );
}

const RelationshipAmountKeyPropTypes = PropTypes.shape({
  name: NameKeyPropTypes,
  amount: PropTypes.number,
  relationship: PropTypes.oneOf(relationshipTypes),
});

RelationshipAmountTypeQuestion.propTypes = {
  ...QuestionPropTypes.isRequired,
  keyData: PropTypes.objectOf(RelationshipAmountKeyPropTypes).isRequired,
};

export default RelationshipAmountTypeQuestion;

export const exampleRelationshipAmountTypeQuestion = {
  question: {
    ID: 'example-relationshipAmountType-id',
    questionID: 1,
    question: "Please list the person's name, relationship and dollar amount of the gift.",
    nextQuestionID: 2,
    keyID: 'example-relationshipAmountType-keyID',
    select: null,
    type: 'RelationshipAmount',
  },
  keyData: {
    name: {
      firstName: 'example-firstName',
      middleName: 'example-middleName',
      lastName: 'example-lastName',
      suffix: null,
    },
    amount: 31.0,
    relationship: 'Spouse/Partner',
  },
};
