import PropTypes from 'prop-types';

const QuestionTypes = [
  'CharityAmountList',
  'Count',
  'Date',
  'Name',
  'NameBirthdate',
  'NameBirthdateListTypeQuestion',
  'NamedPercent',
  'OrgAmount',
  'PBPrimary',
  'Relationship',
  'RelationshipAmount',
  'RelationshipPercent',
  'RelationshipType',
  'Select',
  'State',
  'Static',
  'FinalStatic',
  'TerminatingAge',
];

const SelectPropTypes = PropTypes.objectOf(
  PropTypes.shape({
    display: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    nextQuestion: PropTypes.number,
  }),
);

export const QuestionPropTypes = PropTypes.shape({
  id: PropTypes.string,
  checkChildren: PropTypes.bool,
  keyID: PropTypes.string,
  nextQuestionID: PropTypes.number,
  question: PropTypes.string,
  questionID: PropTypes.number,
  select: SelectPropTypes,
  type: PropTypes.oneOf(QuestionTypes),
  script: PropTypes.string,
});

export const QuestionTypePropTypes = {
  question: QuestionPropTypes.isRequired,
  keyData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
};

export const QuestionTypeDefaultProps = {
  onSubmit: data => console.log(data),
};
