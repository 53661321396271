import React, { PureComponent } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Select, InputNumber } from 'antd';
import { CopyToClipboard, Box, Container, Button, Subtitle, Text, theme, Title } from './common';
import NewPassword from './NewPassword';
import { states } from '../utils/constants';
import { postRequest } from '../utils/network';

const FormItem = Form.Item;

class OrganizationForm extends PureComponent {
  state = {
    newTags: [],
    inputVisible: false,
    inputValue: '',
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  saveInputRef = input => {
    this.input = input;
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { state } = this;
    const { inputValue } = state;
    let { newTags } = state;
    if (inputValue && newTags.filter(tag => tag.label === inputValue).length === 0) {
      newTags = [...newTags, { key: `new-${newTags.length}`, label: inputValue }];
    }
    this.setState({
      newTags,
      inputVisible: false,
      inputValue: '',
    });
  };

  onSubmit = e => {
    const { form, handleSubmit } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const org = {
          name: values.name,
          phone: values.phone,
          user: {
            first_name: values.first_name,
            middle_name: values.middle_name,
            last_name: values.last_name,
            email: values.email,
          },
          address: {
            address_1: values.address_1,
            address_2: values.address_2,
            city: values.city,
            state: values.state,
            postal_code: values.postal_code,
          },
        };
        handleSubmit(org);
        // console.log(org);
        // postRequest('/organizations', org).then((data: respData) => console.log(respData));
      }
    });
  };

  stateSelectOptions = () =>
    Object.keys(states).map(k => (
      <Select.Option key={k} value={k}>
        {states[k]}
      </Select.Option>
    ));

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    const { orgData = { address: {}, user: {} } } = this.props;

    const orgUser = orgData.user;
    const address = orgData.address;

    const buildFormItem = (name, source, placeholder) => (
      <FormItem className="form__item-label" hasFeedback>
        {getFieldDecorator(name, {
          initialValue: source[name],
        })(
          name === 'postal_code' ? (
            <Input size="large" type="text" name={name} placeholder={placeholder} maxLength={5} />
          ) : (
            <Input size="large" type="text" name={name} placeholder={placeholder} />
          ),
        )}
      </FormItem>
    );

    return (
      <>
        <Container maxWidth={28} px={[2, 3, 4]} flexDirection="column" flexAlign="start">
          <Box mb={[3, 4]}>
            <Form className="form" onSubmit={this.onSubmit}>
              {buildFormItem('name', orgData, 'Organization Name')}
              {buildFormItem('phone', orgData, 'Phone Number')}
              <Subtitle>Primary Contact</Subtitle>
              {buildFormItem('first_name', orgUser, 'First Name')}
              {buildFormItem('middle_name', orgUser, 'Middle Name')}
              {buildFormItem('last_name', orgUser, 'Last Name')}
              {buildFormItem('email', orgUser, 'Email')}
              <Subtitle>Mailing Address</Subtitle>
              {buildFormItem('address_1', address, 'Street Address')}
              {buildFormItem('address_2', address, 'Unit Number')}
              {buildFormItem('city', address, 'City')}
              {buildFormItem('state', address, 'State')}
              {buildFormItem('postal_code', address, 'ZIP Code')}
              <Button width={1} type="primary">
                Save
              </Button>
            </Form>
          </Box>
          <Box mb={[3, 4]}>
            <NewPassword />
          </Box>
          <Box mb={[3, 4]}>
            <Subtitle>Referral Code</Subtitle>
            <CopyToClipboard />
            <Text
              style={{ fontWeight: 'bold', textAlign: 'center', color: theme.colors.blacks[5] }}
            >
              <small>Redeemed: 0</small>
            </Text>
          </Box>
        </Container>
      </>
    );
  }
}

export default Form.create()(OrganizationForm);
