import React, { useContext, useState } from 'react';
import { Form } from 'antd';
import { PBGroup } from './PBPrimaryType';
import { UserContext } from '../../contexts/UserContext';
import Button from '../common/Button/Button';
import { selectFieldValue } from './SelectType';
import { relationshipNameKey } from '../../utils/helpers';
import PrevNextBtnGroup from '../PrevNextBtnGroup';
var moment = require('moment');

const GuardianListTypeQuestion = props => {
  const [user, setUser] = useContext(UserContext);
  const { question, keyData, onSubmit, form, handlePrevious } = props;
  const [list] = useState(keyData.guardianList || []);

  const childList = () => {
    if (user.userData == undefined || user.userData.responses == undefined) {
      return [];
    }

    const responses = user.userData.responses;

    let sources = { nameBirthdateList: [] };
    switch (question.keyID) {
      case 'testator_guardian_list':
        sources = responses.testator_children || sources;
        break;
      case 'spouse_guardian_list':
        sources = responses.spouse_children || sources;
        break;
      default:
        sources = responses.children || sources;
        break;
    }

    return sources.nameBirthdateList.filter(child => 18 > moment().diff(child.birthdate, 'years'));
  };

  const prepData = data => {
    const relKeys = ['primary', 'backup'];
    let relationships = [];
    const guardianList = [];

    childList().forEach((child, idx) => {
      const listMap = { child };
      relKeys.forEach(key => {
        const idxKey = `${idx}-${key}`;
        var relValue = selectFieldValue(data, idxKey);
        if (Object.keys(user.userData.relationships).includes(relValue)) {
          relValue = user.userData.relationships[relValue].relationship;
        }
        const respMap = {
          name: {
            firstName: data[`${idxKey}-firstName`],
            middleName: data[`${idxKey}-middleName`] || '',
            lastName: data[`${idxKey}-lastName`],
            suffix: data[`${idxKey}-suffix`] || '',
          },
          relationship: relValue,
        };

        listMap[key] = respMap;
        relationships = [
          ...relationships,
          {
            ...respMap,
            key: relationshipNameKey(respMap.name, relValue),
          },
        ];
      });
      guardianList.push(listMap);
    });

    const response = { guardianList };

    return {
      question,
      response,
      relationships,
    };
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        // console.log(prepData(values));
        onSubmit(prepData(values));
      }
    });
  };

  const pbGroups = childList().map((child, idx) => (
    <PBGroup
      key={child.name.firstName + ' ' + child.name.lastName}
      keyData={list[idx] || { primary: null, backup: null }}
      form={form}
      user={user}
      name={child.name.firstName + ' ' + child.name.lastName}
      namePrefix={idx}
    />
  ));

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {pbGroups}
        <PrevNextBtnGroup handlePrevious={handlePrevious} />
      </Form>
    </>
  );
};

export default Form.create()(GuardianListTypeQuestion);
