import styled from 'styled-components';
import { backgroundColor, lineHeight, color, fontFamily, fontWeight } from 'styled-system';
import { lighten } from 'polished';
import theme from './theme';

const Link = styled.a`
  ${fontFamily} 
  ${color}
  ${backgroundColor}
  ${lineHeight}
  ${fontWeight}
   & {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration-color: ${theme.colors.primaryDark};
    text-decoration-skip-ink: auto;
    &:hover {
      opacity: 1;
      color: ${props => lighten(0.25, theme.colors.primaryDark)};
      text-decoration-color: ${props => lighten(0.25, theme.colors.primaryDark)};
    }
  }
`;

Link.displayName = 'Link';

Link.propTypes = {
  ...color.propTypes,
  ...backgroundColor.propTypes,
  ...fontWeight.propTypes,
  ...lineHeight.propTypes,
};

Link.defaultProps = {
  theme,
  fontFamily: theme.fonts.sansSerifAlt,
  fontWeight: 500,
  backgroundColor: 'transparent',
  lineHeight: '1.4',
};

export default Link;
