import React, { useState, Component } from 'react';
import { Menu, Icon, Tabs } from 'antd';
import { Box, Flex, Container, Link, theme } from './common';
import UsersList from './UsersList';
import RefCodeList from './RefCodeList';
import AdminData from './AdminData';
import Demographics from './Demographics';
import Organizations from './Organizations';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDisplay: <UsersList />,
    };
  }

  handleMenuClick = e => {
    const displayComponents = {
      users: <UsersList />,
      data: <AdminData />,
      ref: <RefCodeList />,
      demo: <Demographics />,
      organizations: <Organizations />,
    };
    this.setState({ currentDisplay: displayComponents[e.key] });
  };

  render() {
    const { currentDisplay } = this.state;

    const menuContainer = {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      height: '4rem',
      backgroundColor: theme.colors.darkGray,
    };

    const altMenuContainer = {
      justifyContent: 'center',
      alignItems: 'center',
      height: '4rem',
      margin: 'auto',
      backgroundColor: theme.colors.blacks[3],
    };

    const menuIconStyle = {
      fontSize: '16px',
      color: theme.colors.secondaryLight,
    };

    const adminNavStyle = {
      fontSize: '0.8rem',
      fontWeight: 700,
      textTransform: 'uppercase',
      color: theme.colors.white,
    };

    return (
      <>
        <Box style={menuContainer}>
          <Flex style={altMenuContainer}>
            <Menu
              onClick={this.handleMenuClick}
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={['users']}
            >
              <Menu.Item key="users" style={adminNavStyle}>
                <Icon type="user" style={menuIconStyle} />
                Users
              </Menu.Item>
              <Menu.Item key="data" style={adminNavStyle}>
                <Icon type="line-chart" style={menuIconStyle} />
                Prequalification
              </Menu.Item>
              <Menu.Item key="ref" style={adminNavStyle}>
                <Icon type="swap" style={menuIconStyle} />
                Referral Codes
              </Menu.Item>
              <Menu.Item key="demo" style={adminNavStyle}>
                <Icon type="global" style={menuIconStyle} />
                Demographics
              </Menu.Item>
              <Menu.Item key="organizations" style={adminNavStyle}>
                <Icon type="team" style={menuIconStyle} />
                Organizations
              </Menu.Item>
            </Menu>
          </Flex>
          <Container px={[2, 3, 4]} py={[3, 4]} flexDirection="row">
            {currentDisplay}
          </Container>
        </Box>
      </>
    );
  }
}

export default Admin;
