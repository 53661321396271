import React from 'react';
import { createBrowserHistory } from 'history';
import Handlebars from 'handlebars';
import { Select, notification } from 'antd';
import { stateExecutors } from './constants';

const history = createBrowserHistory({
  forceRefresh: true,
});

// arrayOptions provides a list of options for a select field from an array of strings
export const arrayOptions = (arr, def = '') => {
  var vals = arr.map(item => (
    <Select.Option key={item} value={item}>
      {item}
    </Select.Option>
  ));

  if (def != '') {
    const defaultOption = (
      <Select.Option key="" value="">
        {def}
      </Select.Option>
    );
    vals = [defaultOption, ...vals];
  }

  return vals;
};

// mapOptions provides a list of options for a select field from a map of keys/values
// {"key": "value"}
// key is the value returned from the option, with value being the displayed option
export const mapOptions = m =>
  Object.keys(m).map(k => (
    <Select.Option key={k} value={k}>
      {m[k]}
    </Select.Option>
  ));

export const safeParseFloat = f => {
  const num = Number.parseFloat(f);
  return Number.isNaN(num) ? 0 : num;
};

export const safeParseInt = i => {
  const num = Number.parseInt(i, 10);
  return Number.isNaN(num) ? 0 : num;
};

export const empty = str => str === undefined || str.length === 0;

export const fieldName = (prefix, name) => {
  return empty(prefix) ? name : `${prefix}-${name}`;
};

export const relationshipNameKey = (name, relationship) => {
  const { firstName, middleName, lastName, suffix } = name;
  return [relationship, '-', firstName, middleName, lastName, suffix]
    .filter(item => item != undefined && item.length > 0)
    .join(' ')
    .trim();
};

export const formatQuestion = (responses, question) => {
  // test/example data
  // const q = 'Hello {{user-state.state}} {{list-test.list.length}}';
  // const data = {
  //   'user-state': { state: 'Tester' },
  //   'has-children': { select: 'Yes' },
  //   'list-test': { list: [ 1, 2, 3 ] },
  // };
  const template = Handlebars.compile(String(question));
  Handlebars.registerHelper('if_greater_than', function(a, b, opts) {
    if (a > b) {
      return opts.fn(this);
    } else {
      return opts.inverse(this);
    }
  });

  const formatted = template(responses);
  const splits = formatted.split('\\n');

  for (let index = 0; index < splits.length; index += 2) {
    // if last element, keep moving
    if (index == splits.length - 1) {
      break;
    }
    splits.splice(index + 1, 0, <br />);
  }

  return splits;
};

export const formatReasons = (user, message) => {
  if (
    user &&
    user.userData &&
    user.userData.responses &&
    user.userData.responses.state &&
    message
  ) {
    const template = Handlebars.compile(String(message));
    const data = { executor: stateExecutors[user.userData.responses.state.state] };
    // return template(data);
    const formatted = template(data);
    const splits = formatted.split('\\n');

    for (let index = 0; index < splits.length; index += 2) {
      // if last element, keep moving
      if (index == splits.length - 1) {
        break;
      }
      splits.splice(index + 1, 0, <br />);
    }

    return splits;
  }
  return message;
};

export function notificationRedirect(redirectUrl, id) {
  notification.success({
    message: 'Success',
    description: 'Your changes have been successfully saved.',
    onClick: () => {
      console.log('Notification Clicked!');
    },
  });
  setTimeout(() => history.push(redirectUrl + `/${id}`), 1000);
}
