import React, { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import propTypes from 'prop-types';
import { Box, Button, theme, Layout, Link, Text, Title, Flex } from './components/common';
import './App.css';
import PrequalQuestionsContainer from './containers/PrequalQuestions_container';
import StripeFormContainer from './containers/StripeFormContainer';
import QuestionContainer from './components/questionsTypes/QuestionContainer';
import { QuestionProvider } from './components/questionsTypes/QuestionContext';
import { UserProvider, UserContext } from './contexts/UserContext';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import Account from './components/Account';
import Organizations from './components/Organizations';
import { delStoredToken } from './utils/auth';
import Admin from './components/Admin';
import QuestionHistoryContainer from './containers/QuestionHistoryContainer';
import HistoryContainer from './containers/HistoryContainer';
import AccountRecovery from './components/AccountRecovery';
import { createBrowserHistory } from 'history';
import EditOrganization from './components/EditOrganization';
import NewOrganization from './components/NewOrganization';

const history = createBrowserHistory({
  forceRefresh: true,
});

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

AppRoute.propTypes = {
  ...propTypes.component,
  ...propTypes.layout,
};

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const [user, _] = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={props =>
        user.loggedIn ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const AdminRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const [user, _] = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={props =>
        user.isAdmin ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const AppWrapper = styled.div`
  position: relative;
`;

const styles = {
  ctaBtn: {
    fontSize: '4rem !important',
  },
  resumeText: {
    letterSpacing: '-0.25rem',
  },
  resumeLink: {
    letterSpacing: '-0.25rem',
    textDecoration: 'underline',
  },
};

const Home = () => {
  const [user, setUser] = useContext(UserContext);

  const logout = e => {
    e.preventDefault();

    console.log('logout');
    delStoredToken();
    setUser({
      loggedIn: false,
      userData: {},
      username: '',
    });
  };

  return (
    <Flex alignSelf="flex-start" flexDirection="column">
      <Flex alignSelf="flex-start" pb={[3, 4]}>
        <Title style={{ fontSize: 'calc(2rem + (4-2) * (100vw - 400px)/(900-400))', padding: 0 }}>
          Wills are for old people.
          <br />
          Willingworth is for you.
        </Title>
      </Flex>
      <Flex alignSelf="flex-start">
        <Button
          onClick={() => history.push(user.loggedIn ? '/questions' : '/prequalification')}
          type="primary"
          size="large"
          styles={styles.ctaBtn}
        >
          Start Your Will
        </Button>
      </Flex>
      <Box mt={[2, 3]}>
        <Text size="small" styles={styles.resumeText}>
          Already have a will in progress?{' '}
          <Link
            styles={styles.resumeLink}
            fontWeight={600}
            color={theme.colors.primaryDark}
            borderBottom={2}
            borderWidth={2}
            href="/questions"
          >
            Click here to resume
          </Link>
          .
        </Text>
      </Box>
    </Flex>
  );
};

const App = () => (
  <ThemeProvider theme={{ theme }}>
    <UserProvider>
      <Router>
        <AppWrapper>
          <Route exact path="/" render={() => <Redirect to="/history" />} />
          <PrivateRoute path="/account" layout={Layout.MainLayout} component={Account} />
          <AppRoute path="/login" layout={Layout.AltLayout} component={Login} />
          <AppRoute path="/password-reset" layout={Layout.MainLayout} component={ForgotPassword} />
          <AppRoute
            path="/account_recovery/:token"
            layout={Layout.AltLayout}
            component={AccountRecovery}
          />
          <QuestionProvider>
            <PrivateRoute
              exact
              path="/questions"
              layout={Layout.FauxLayout}
              component={QuestionContainer}
            />
            <PrivateRoute
              path="/questions/:qid"
              layout={Layout.FauxLayout}
              component={QuestionContainer}
            />
          </QuestionProvider>
          <AppRoute
            path="/prequalification"
            layout={Layout.AltLayout}
            component={PrequalQuestionsContainer}
          />
          <PrivateRoute
            path="/payment"
            layout={Layout.MainLayout}
            component={StripeFormContainer}
          />
          <AdminRoute path="/admin" layout={Layout.AdminLayout} component={Admin} />
          <PrivateRoute
            exact
            path="/review"
            layout={Layout.FauxLayout}
            component={QuestionHistoryContainer}
          />
          <PrivateRoute
            path="/review/:datumID"
            layout={Layout.FauxLayout}
            component={QuestionHistoryContainer}
          />
          <PrivateRoute path="/history" layout={Layout.MainLayout} component={HistoryContainer} />
          <AdminRoute
            exact
            strict
            path="/new_organization"
            layout={Layout.MainLayout}
            component={NewOrganization}
          />
          <AdminRoute
            path="/organizations/:orgid"
            layout={Layout.MainLayout}
            component={EditOrganization}
          />
        </AppWrapper>
      </Router>
    </UserProvider>
  </ThemeProvider>
);

export default hot(App);
