import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Table } from 'antd';
import { Container, Flex, Heading } from './common';
import Chart from 'chart.js';
import { getRequest } from '../utils/network';

var moment = require('moment');

const AdminData = props => {
  const [activities, setActivities] = useState({});
  const [launched, setLaunched] = useState(false);
  useEffect(() => {
    if (!launched) {
      fetchData();
    }
  });

  const groupActivities = (activities = []) => {
    var groupings = {};
    activities.forEach(activity => {
      var group = groupings[activity.type] || [];
      group.push(activity);
      groupings[activity.type] = group;
    });
    return groupings;
  };

  const fetchData = () => {
    getRequest('/activities').then(({ data: respData }) => {
      const groups = groupActivities(respData.activities);
      setActivities(groups);
    });
    setLaunched(true);
  };

  const typeDisplay = () => {
    return Object.keys(activities || {}).map(type => {
      const grouping = activities[type];
      const valueGroupings = {};
      grouping.forEach(activity => {
        var group = valueGroupings[activity.value] || [];
        group.push(activity);
        valueGroupings[activity.value] = group;
      });
      console.log(valueGroupings);

      const typeCount = grouping.length;

      const joinedDisplay = time => `${moment(time).format('MMM DD, YYYY')}`;

      const typeTitle = type == 'prequalSuccess' ? 'Successfully Pre-qualified ' : 'Disqualified ';

      const columns = [
        {
          title: 'Type',
          dataIndex: 'type',
          render: (text, activity) => activity.type,
        },
        {
          title: 'Value',
          dataIndex: 'value',
          key: 'value',
          render: (text, activity) => activity.value,
          filters: [
            { text: 'Age', value: 'ageCheck' },
            { text: 'Children', value: 'childrenCheck' },
            { text: 'Marriage', value: 'marriageCheck' },
          ],
          onFilter: (value, record) => {
            return record.value === value;
          },
        },
        {
          title: 'Date',
          dataIndex: 'created_at',
          render: text => joinedDisplay(text),
          sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
        },
      ];

      const countNum = {
        fontSize: '2.4rem',
        fontWeight: '400',
        lineHeight: 1.2,
        textAlign: 'center',
      };

      const countTitle = {
        fontSize: '1rem',
        fontWeight: '500',
      };

      return (
        <Table
          key={type}
          columns={columns}
          dataSource={grouping}
          rowKey={activity => activity.id}
          title={() => typeTitle + '(' + typeCount + ')'}
          bordered
        />
      );
    });
  };

  const gridStyle = {
    width: '50%',
    textAlign: 'center',
  };

  const valueDisplay = groupings => {
    return Object.keys(groupings || {}).map(value => {
      const grouping = groupings[value];
      const count = grouping.length;

      const typeTitle =
        value != 'success' ? (
          <Row key={value}>
            <Col>{`${value}`}</Col>
            <Col>{`${count}`}</Col>
          </Row>
        ) : null;

      return typeTitle;
    });
  };

  return (
    <>
      <Heading>Prequalification</Heading>
      <Flex
        flexDirection="row"
        justifyContent="space-around"
        width="60rem"
        px={[2, 3, 4]}
        mb={[2, 3]}
      >
        {typeDisplay()}
      </Flex>
    </>
  );
};

export default AdminData;
