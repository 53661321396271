import React, { useState, createContext } from 'react';

export const QuestionContext = createContext();

export const QuestionProvider = ({ children }) => {
  const [currentQuestion, setCurrentQuestion] = useState({
    question: {
      questionID: 1,
      ID: '',
      question: '',
      nextQuestionID: 1,
      keyID: 'start-here-id',
      select: null,
      type: 'Static',
      checkChildren: false,
    },
    keyData: {
      body: 'Loading Questions',
      buttonText: 'Next',
    },
  });
  return (
    <QuestionContext.Provider value={[currentQuestion, setCurrentQuestion]}>
      {children}
    </QuestionContext.Provider>
  );
};
