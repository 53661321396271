import React from 'react';
import Box from './Box';

export const Grid = props => <Box {...props} verticalAlign="top" px={2} />;

export const Half = props => <Grid {...props} width={[1, 1 / 2]} />;

export const Third = props => <Grid {...props} width={[1, 1 / 3]} />;

export const Quarter = props => (
  <Grid {...props} display={['flex', 'flex', 'inline-flex']} width={[1, 1, 1 / 4]} />
);

export const TwoThird = props => <Grid {...props} width={[1, 2 / 3]} />;

export const ThreeQuarter = props => <Grid {...props} width={[1, 1, 3 / 4]} />;

export const Flex = props => <Box {...props} display="flex" />;

export const FlexAuto = props => <Box {...props} flex="1 1 auto" />;
