import React from 'react';
import { QuestionPropTypes } from './QuestionPropTypes';
import { Row, Col, Form } from 'antd';
import { Container, Button, Box, Flex, Heading } from '../common';
import PrevNextBtnGroup from '../PrevNextBtnGroup';

function StaticTypeQuestion(props) {
  const { question, keyData, onSubmit, handlePrevious } = props;

  const handleSubmit = ev => {
    ev.preventDefault();
    onSubmit({
      question,
      keyData,
    });
  };

  return (
    <>
      <Container width={1} px={[3, 5]} py={[1, 2]}>
        <Box mt={[4, 5]}>
          <Heading style={{ maxWidth: '900px', textAlign: 'left' }}>{question.question}</Heading>
        </Box>
      </Container>
      <Form>
        <PrevNextBtnGroup handlePrevious={handlePrevious} onSubmit={handleSubmit} />
      </Form>
    </>
  );
}

StaticTypeQuestion.propTypes = {
  ...QuestionPropTypes,
  // keyData: PropTypes.objectOf(
  //   PropTypes.shape({
  //     body: PropTypes.string,
  //     buttonText: PropTypes.string,
  //   }),
  // ).isRequired,
};

export default StaticTypeQuestion;

export const exampleStaticTypeQuestion = {
  question: {
    ID: 'example-statictype-id',
    questionID: 1,
    question: 'Title​',
    nextQuestionID: 2,
    keyID: 'example-statictype-keyID',
    select: null,
    type: 'Static',
  },
  keyData: {
    body:
      'Example: You have finished the Responsible People Section. In this last section you will decide who receives your property.​ \n\nYou can leave your property to @Testatorfirstname and your children.​ \nYou can leave specific amounts of money to organizations, charities or individuals other than @testatorfirstname and your children. \nYou can leave specific items of personal property (see sidebar) to specific individuals in the memorandum attached to the end of your will.',
    buttonText: 'Next Question',
  },
};
