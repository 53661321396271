import React, { useContext } from 'react';
import styled from 'styled-components';
import FileSaver, { saveAs } from 'file-saver';
import { Menu, Layout } from 'antd';
import logoWhite from '../horiz_white.svg';
import { Button, Link, Logo, Text, theme } from './common';
import { UserContext } from '../contexts/UserContext';
import { delStoredToken } from '../utils/auth';
import { getRequest } from '../utils/network';
import Admin from './Admin';

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const { Header } = Layout;

const AppHeader = () => {
  const [user, setUser] = useContext(UserContext);

  const logout = e => {
    e.preventDefault();

    console.log('logout');
    delStoredToken();
    setUser({
      loggedIn: false,
      userData: {},
      username: '',
    });
  };

  const docs = e => {
    e.preventDefault();

    // Fetch the dynamically generated zip file from the server.
    getRequest('/docs.zip', {}, 'blob')
      .then(response => {
        // Log somewhat to show that the browser actually exposes the custom HTTP header
        const fileNameHeader = 'x-suggested-filename';
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName = suggestedFileName === undefined ? 'docs.zip' : suggestedFileName;
        console.log(
          'Received header [' +
            fileNameHeader +
            ']: ' +
            suggestedFileName +
            ', effective fileName: ' +
            effectiveFileName,
        );

        // Let the user save the file.
        saveAs(response.data, effectiveFileName);
      })
      .catch(err => {
        console.error('Could not Download the Zip from the backend.', err);
      });
  };

  const styles = {
    navLink: {
      alignItems: 'flex-end',
      color: user.isAdmin ? 'white' : '',
    },
    navLinkButton: {
      width: 'auto',
      lineHeight: 1,
      minWidth: '90px',
      color: 'white',
      borderRadius: '5px',
    },
  };

  return (
    <HeaderWrapper style={{ backgroundColor: user.isAdmin ? theme.colors.darkGray : '' }}>
      <Header className="header">
        {user.isAdmin ? <img alt="white logo" src={logoWhite} /> : <Logo alt="Willingworth Logo" />}
        <Menu
          theme="dark"
          mode="horizontal"
          className="header__nav nav"
          defaultSelectedKeys={['1']}
        >
          {user.loggedIn ? (
            user.isAdmin ? (
              <>
                <Link style={styles.navLink} className="nav-link" href="/admin">
                  Admin Panel
                </Link>
                <Link style={styles.navLink} className="nav-link" href="/account">
                  Account
                </Link>
                <Button style={styles.navLinkButton} className="nav-link" onClick={logout}>
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Link style={styles.navLink} className="nav-link" href="/account">
                  Account
                </Link>
                <Link style={styles.navLink} className="nav-link" href="/history/">
                  Your Documents
                </Link>
                <Button style={styles.navLinkButton} className="nav-link" onClick={logout}>
                  Logout
                </Button>
              </>
            )
          ) : (
            <>
              <Link style={{ marginRight: '1rem' }} href="/login">
                <Button style={styles.navLinkButton} className="nav-link">
                  Login
                </Button>
              </Link>
              <Link href="/prequalification">
                <Button style={styles.navLinkButton} className="nav-link">
                  Sign Up
                </Button>
              </Link>
            </>
          )}
        </Menu>
      </Header>
    </HeaderWrapper>
  );
};

export default AppHeader;
