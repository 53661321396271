import React from 'react';
import styled from 'styled-components';
import { createBrowserHistory } from 'history';
import Container from './Container';
import Flex from './Flex';
import Logo from './Logo';
import AppHeader from '../Header';
import AppFooter from '../Footer';

const history = createBrowserHistory({
  forceRefresh: true,
});

const Layout = React.forwardRef((props, ref) => (
  <Container flexGrow={1} alignItems="flex-start" px={[2, 3, 4]} py={[2, 3]}>
    {props.children}
  </Container>
));

Layout.displayName = 'Layout';

const MainLayoutWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
  background-image: url('logo-icon-only.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: calc(100% + 30vw) calc(100% - 37.5vh);
  position: relative;
`;

const MainLayout = React.forwardRef((props, ref) => (
  <MainLayoutWrapper>
    <AppHeader />
    <Layout>{props.children}</Layout>
    <AppFooter />
  </MainLayoutWrapper>
));

MainLayout.displayName = 'MainLayout';

const AltLayout = React.forwardRef((props, ref) => (
  <Layout>
    <Flex justifyContent="center" py={[2, 3, 4]} flexDirection="column">
      <Logo
        onClick={() => (window.location.href = 'https://willingworth.com')}
        alt="Willingworth Logo"
      />
    </Flex>
    {props.children}
  </Layout>
));

AltLayout.displayName = 'altLayout';

const AdminLayout = React.forwardRef((props, ref) => (
  <>
    <AppHeader style={{ backgroundColor: '#354049' }} />
    <Container flexGrow={1} alignItems="flex-start" px={0} py={0}>
      {props.children}
    </Container>
  </>
));

AdminLayout.displayName = 'adminLayout';

const FauxLayout = React.forwardRef((props, ref) => <div>{props.children}</div>);

FauxLayout.displayName = 'fauxLayout';

const Layouts = {
  Layout,
  MainLayout,
  AltLayout,
  AdminLayout,
  FauxLayout,
};

export default Layouts;
