import React, { useState, createContext } from 'react';
import { getStoredToken, isTokenValid, isAdminTokenValid } from '../utils/auth';

export const UserContext = createContext({});
export const getCurrentUser = () => JSON.parse(localStorage.getItem('currentUser'));

export const UserProvider = ({ children }) => {
  const token = getStoredToken();
  const currentUser = getCurrentUser();

  const [user, setUser] = useState(
    currentUser
      ? {
          loggedIn: isTokenValid(token),
          isAdmin: isAdminTokenValid(token),
          userData: {},
          username: '',
          referrer_name: '',
          id: currentUser.id,
          first_name: currentUser.first_name,
          middle_name: currentUser.middle_name,
          last_name: currentUser.last_name,
          email: currentUser.email,
          postal_code: currentUser.postal_code,
        }
      : {
          loggedIn: isTokenValid(token),
          isAdmin: isAdminTokenValid(token),
          userData: {},
          username: '',
          referrer_name: '',
          id: '',
          first_name: '',
          middle_name: '',
          last_name: '',
          email: '',
          postal_code: '',
        },
  );
  return <UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>;
};

export const userRelationshipMap = (relationships = {}) => {
  const relMap = {};
  Object.keys(relationships).map(key => {
    relMap[key] = {
      display: key,
    };
  });
  return relMap;
};
