import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form, Select, Input, InputNumber, Row, Icon } from 'antd';
import { fieldName, mapOptions, relationshipNameKey } from '../../utils/helpers';
import { Subtitle, Flex, Button, Box, theme } from '../common';
import { states, relationshipMap, relationshipMapStatus } from '../../utils/constants';
import { SelectField, selectFieldValue } from './SelectType';
import { QuestionPropTypes } from './QuestionPropTypes';
import { NameFields } from './NameType';
import { UserContext, userRelationshipMap } from '../../contexts/UserContext';
import PrevNextBtnGroup from '../PrevNextBtnGroup';
import { PBFields } from './PBPrimaryType';

const TrashButton = styled(Button.Outline)`
  color: ${theme.colors.primary};
  padding: 0;
  border: 0;
  font-size: 1rem;
  margin-top: -7px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;

  &:hover {
    box-shadow: none;
    color: #f38988
    background-color: rgba(32, 78, 95, 0.1);
  }

  &:active,
  &:focus {
    outline: none;
  }
`;

const INSTITUTION_TYPE = 'institution';
const CHARITY_TYPE = 'charity';
const INDIVIDUAL_TYPE = 'individual';

const VALID_TYPES = [INSTITUTION_TYPE, CHARITY_TYPE, INDIVIDUAL_TYPE];

const defaultAddrValues = {
  addr1: '',
  addr2: '',
  city: '',
  state: 'AL',
  postalCode: '',
};

const defaultCharityDetails = {
  name: '',
  address: defaultAddrValues,
};

const defaultIndividualDetails = {
  name: {
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: null,
  },
  relationship: 'Sister',
};

function CharityAmountListTypeQuestion(props) {
  const [user, setUser] = useContext(UserContext);
  const { question, keyData, onSubmit, form, form: { getFieldDecorator }, handlePrevious } = props;
  const [gifts, setGifts] = useState(keyData.charityAmountList || []);

  const prepCharityDetails = (data, idx) => ({
    amount: data[`${idx}-amount`],
    address: {
      addr1: data[`${idx}-addr1`],
      addr2: data[`${idx}-addr2`],
      city: data[`${idx}-city`],
      state: data[`${idx}-state`],
      postalCode: data[`${idx}-postalCode`],
    },
    name: data[`${idx}-name`],
    type: CHARITY_TYPE,
  });

  const prepIndividualDetails = (data, idx) => {
    var relValue = selectFieldValue(data, `${idx}`);
    if (Object.keys(user.userData.relationships).includes(relValue)) {
      relValue = user.userData.relationships[relValue].relationship;
    }
    return {
      amount: data[`${idx}-amount`],
      name: {
        firstName: data[`${idx}-firstName`],
        middleName: data[`${idx}-middleName`],
        lastName: data[`${idx}-lastName`],
        suffix: data[`${idx}-suffix`],
      },
      relationship: relValue,
      type: INDIVIDUAL_TYPE,
    };
  };

  const prepInstitutionDetails = (data, idx) => ({
    amount: selectFieldValue(data, `${idx}-amount`),
    name: data[`${idx}-name`],
    type: INSTITUTION_TYPE,
  });

  const prepData = data => {
    const response = { charityAmountList: [] };
    const relationships = [];
    for (let idx = 0; idx < gifts.length; idx += 1) {
      const giftType = gifts[idx].type;
      let preppedData = data;
      switch (giftType) {
        case CHARITY_TYPE:
          preppedData = prepCharityDetails(data, idx);
          preppedData.amount = data[`${idx}-amount`];
          break;
        case INDIVIDUAL_TYPE:
          preppedData = prepIndividualDetails(data, idx);
          relationships.push({
            name: preppedData.name,
            relationship: preppedData.relationship,
            key: relationshipNameKey(preppedData.name, preppedData.relationship),
          });
          break;
        default:
          preppedData = prepInstitutionDetails(data, idx);
          break;
      }
      response.charityAmountList[idx] = preppedData;
    }
    return {
      question,
      response,
      relationships,
    };
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(prepData(values));
      }
    });
  };

  const addGift = ev => {
    ev.preventDefault();
    const newGift = {
      type: CHARITY_TYPE,
      amount: null,
      details: defaultCharityDetails,
    };
    const updatedGifts = [...gifts, newGift];
    setGifts(updatedGifts);
  };

  const removeGift = ev => {
    ev.preventDefault();
    const updatedGifts = [...gifts];
    const idx = parseInt(ev.target.name, 10);
    updatedGifts.splice(idx, 1);
    setGifts(updatedGifts);
  };

  const changeType = (value, name) => {
    if (VALID_TYPES.includes(value)) {
      const idx = name.split('-')[0];
      const updatedGifts = [...gifts];
      updatedGifts[idx].type = value;
      switch (value) {
        case CHARITY_TYPE:
          updatedGifts[idx].details = defaultCharityDetails;
          break;
        case INDIVIDUAL_TYPE:
          updatedGifts[idx].details = defaultIndividualDetails;
          break;
        default:
          updatedGifts[idx].details = {
            name: hasReferrerInstitutionGift() ? '' : user.referrer_name,
          };
          break;
      }
      setGifts(updatedGifts);
    }
  };

  const typeOptions = () => {
    var options = {
      charity: {
        display: 'Charity',
      },
      individual: {
        display: 'Individual',
      },
      institution: {
        display: 'Institution',
      },
    };

    return options;
  };

  const amountField = (amount, idx) => (
    <>
      <Subtitle>Amount</Subtitle>
      <Row type="flex" justify="center">
        <Form.Item>
          {getFieldDecorator(`${idx}-amount`, {
            initialValue: amount,
            rules: [{ required: true, message: 'Amount is Required' }],
          })(
            <InputNumber
              size="large"
              min={0}
              formatter={value => `$${value}`}
              precision={2}
              parser={value => value.replace('$', '')}
            />,
          )}
        </Form.Item>
      </Row>
    </>
  );

  const charityForm = (gift, idx) => {
    const { amount, address, name } = gift;
    return (
      <>
        <Subtitle>Charity Name</Subtitle>
        <Row type="flex" justify="center">
          <Form.Item>
            {getFieldDecorator(`${idx}-name`, {
              initialValue: name,
            })(<Input size="large" />)}
          </Form.Item>
        </Row>
        <Subtitle>Address</Subtitle>
        <Row type="flex" justify="center">
          <Box>
            <AddressFields
              defaults={address || defaultAddrValues}
              namePrefix={`${idx}`}
              form={form}
            />
          </Box>
        </Row>
        {amountField(amount, idx)}
      </>
    );
  };

  const individualForm = (gift, idx) => {
    const { amount, name, relationship } = gift;

    const relationshipOptions = {
      ...userRelationshipMap(user.userData.relationships),
      ...relationshipMapStatus(user.userData.responses['relationship_status']),
    };

    const keyData = { name, relationship };

    return (
      <>
        <PBFields
          title="Individual Name"
          keyData={keyData}
          form={form}
          prefix={`${idx}`}
          relationships={user.userData.relationships}
          relationshipOptions={relationshipOptions}
          skipReset
        />
        {amountField(amount, idx)}
      </>
    );
  };

  const institutionAmounts = {
    1000: {
      display: '$1,000',
    },
    2500: {
      display: '$2,500',
    },
    5000: {
      display: '$5,000',
    },
    10000: {
      display: '$10,000',
    },
    Other: {
      display: 'Other',
    },
  };

  const hasReferrerInstitutionGift = () => {
    // const institutionGifts = gifts.filter(gift => gift.type === INSTITUTION_TYPE);
    const formValues = form.getFieldsValue();
    for (let idx = 0; idx < gifts.length; idx++) {
      const typeSelect = formValues[`${idx}-type-select`];
      if (typeSelect === INSTITUTION_TYPE) {
        const giftName = formValues[`${idx}-name`];
        if (giftName === user.referrer_name) {
          return true;
        }
      }
    }
    return false;
  };

  const institutionForm = (gift, idx) => {
    var { amount, name } = gift;
    return (
      <>
        <Subtitle>Institution Name</Subtitle>
        <Row type="flex" justify="center">
          <Form.Item>
            {getFieldDecorator(`${idx}-name`, {
              initialValue: name,
            })(<Input size="large" />)}
          </Form.Item>
        </Row>
        <br />
        <SelectField
          optionList={institutionAmounts}
          defaultValue={amount}
          namePrefix={`${idx}-amount`}
          form={form}
          isRequired
        />
      </>
    );
  };

  const typeForm = (gift, idx) => {
    switch (gift.type) {
      case CHARITY_TYPE:
        return charityForm(gift, idx);
      case INDIVIDUAL_TYPE:
        return individualForm(gift, idx);
      default:
        return institutionForm(gift, idx);
    }
  };

  const items = gifts.map((gift, idx) => (
    <React.Fragment key={idx}>
      <Row type="flex" justify="center">
        <Flex flexDirection="center" justifyContent="center">
          <Subtitle
            style={{ display: 'inline-block', marginLeft: 0, marginRight: '1rem', width: 'auto' }}
          >{`Gift #${idx + 1}`}</Subtitle>
          <TrashButton type="button" onClick={removeGift} name={`${idx}`}>
            <Icon type="delete" theme="filled" color={theme.colors.primary} />
          </TrashButton>
        </Flex>
      </Row>
      <SelectField
        optionList={typeOptions()}
        defaultValue={gift.type}
        namePrefix={`${idx}-type`}
        onChange={changeType}
        form={form}
        isRequired
      />
      <br />

      {typeForm(gift, idx)}
      <hr />
    </React.Fragment>
  ));

  return (
    <Form onSubmit={handleSubmit}>
      {items}
      <Flex alignItems="center" flexDirection="column" justifyContent="center">
        <Button m={2} maxWidth="350px" type="button" onClick={addGift}>
          {items.length > 0 ? 'Add Another Gift' : 'Add Gift'}
        </Button>
      </Flex>
      <PrevNextBtnGroup handlePrevious={handlePrevious} />
    </Form>
  );
}

export const CharityAmountListTypeDisplay = props => {
  const { data: { charityAmountList: list } } = props;
  return list.map(item => {
    const address = item.address || {};
    switch (item.type) {
      case INDIVIDUAL_TYPE:
        return `Individual: $${item.amount} to ${item.name.firstName} ${item.name.middleName} ${
          item.name.lastName
        } ${item.name.suffix || ''}, ${item.relationship}`;
      case CHARITY_TYPE:
        return `Charity: $${item.amount} to ${item.name}, ${address.addr1 || ''} ${address.addr2 ||
          ''} ${address.city}, ${address.state} ${address.postalCode}`;
      case INSTITUTION_TYPE:
        return `Institution: ${item.amount} to ${item.name ||
          (item.details ? item.details.name : '')}`;
      default:
        return `${item.type}`;
    }
  });
};

CharityAmountListTypeQuestion.propTypes = {
  ...QuestionPropTypes,
  // keyData: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default Form.create()(CharityAmountListTypeQuestion);

const AddressFields = props => {
  const { defaults, namePrefix, form: { getFieldDecorator } } = props;

  const addr1 = fieldName(namePrefix, 'addr1');
  const addr2 = fieldName(namePrefix, 'addr2');
  const city = fieldName(namePrefix, 'city');
  const state = fieldName(namePrefix, 'state');
  const postalCode = fieldName(namePrefix, 'postalCode');

  return (
    <>
      <Form.Item>
        {getFieldDecorator(addr1, {
          initialValue: defaults.addr1,
          rules: [{ required: true, message: 'Street Address is Required' }],
        })(<Input size="large" type="text" placeholder="Street Address" />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator(addr2, {
          initialValue: defaults.addr2,
        })(<Input size="large" type="text" placeholder="Suite/Unit #" />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator(city, {
          initialValue: defaults.city,
          rules: [{ required: true, message: 'City is Required' }],
        })(<Input size="large" type="text" placeholder="City" />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator(state, {
          initialValue: defaults.state,
        })(<Select size="large">{mapOptions(states)}</Select>)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator(postalCode, {
          initialValue: defaults.postalCode,
          rules: [{ required: true, message: 'Zip/Postal Code is Required' }],
        })(<Input size="large" type="text" placeholder="Zip/Postal Code" />)}
      </Form.Item>
    </>
  );
};

AddressFields.propTypes = {
  register: PropTypes.func.isRequired,
  defaults: PropTypes.shape({
    addr1: PropTypes.string.isRequired,
    addr2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }).isRequired,
  namePrefix: PropTypes.string,
};

AddressFields.defaultProps = {
  namePrefix: '',
};

export const exampleCharityAmountListTypeQuestion = {
  question: {
    ID: 'example-charityamountlisttype-id',
    questionID: 1,
    question:
      'Would you like to carve out a special gift in your will for an organization, charity or individual? ',
    nextQuestionID: 2,
    keyID: 'example-charityamountlisttype-keyID',
    select: null,
    type: 'CharityAmountList',
  },
  keyData: {
    charityAmountList: [
      {
        type: INDIVIDUAL_TYPE,
        amount: 100.0,
        details: {
          name: {
            firstName: 'example-firstName',
            middleName: 'example-middleName',
            lastName: 'example-lastName',
            suffix: null,
          },
          relationship: 'Sister',
        },
      },
      {
        type: CHARITY_TYPE,
        amount: 100.0,
        details: {
          name: 'example-charity-name',
          address: {
            addr1: 'Test street address',
            addr2: '',
            city: 'Birmingham',
            state: 'AL',
            postalCode: '35209',
          },
        },
      },
      {
        name: 'example-institution-name',
        amount: 2500.0,
        type: INSTITUTION_TYPE,
        details: {
          name: 'example-institution-name',
        },
      },
    ],
  },
};
