import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Table, Form, DatePicker } from 'antd';
import { Box, Container, Button, Flex, Heading, Subtitle, theme } from './common';
import { getRequest, downloadRequest } from '../utils/network';

const { RangePicker } = DatePicker;

const Demographics = props => {
  const { form, form: { getFieldDecorator } } = props;

  const [marital_statuses, setMaritalStatuses] = useState({});
  const [ages, setAges] = useState({});
  const [children, setChildren] = useState({});
  const [postal_codes, setPostalCodes] = useState({});
  const [total_users, setTotalUsers] = useState([]);
  const [launched, setLaunched] = useState(false);

  useEffect(() => {
    if (!launched) {
      fetchDemographics(0);
    }
  });

  const fetchDemographics = () => {
    getRequest('/demographics').then(({ data: respData }) => {
      setMaritalStatuses(respData.marital_statuses);
      setAges(respData.ages);
      setChildren(respData.children);
      setPostalCodes(respData.postal_codes);
      setTotalUsers(respData.total_users);
    });
    setLaunched(true);
  };

  const downloadCSV = e => {
    e.preventDefault();

    form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }

      const dateValues = fieldsValue['range-picker'];
      const body = {
        start: dateValues[0],
        end: dateValues[1],
      };
      downloadRequest('/demographics/csv', body).then(resp => {
        resp.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'demographics.csv';
          a.click();
        });
      });
    });
  };

  const maritalStatusLabels = Object.keys(marital_statuses);
  const maritalStatusData = Object.values(marital_statuses);

  const ageLabels = Object.keys(ages);
  const ageData = Object.values(ages);

  const childrenLabels = Object.keys(children);
  const childrenData = Object.values(children);

  const postalCodeData = [];
  for (let [key, value] of Object.entries(postal_codes)) {
    postalCodeData.push({
      key: key,
      count: value,
    });
  }

  const countNum = {
    fontSize: '3rem',
    fontWeight: '700',
    lineHeight: 1.2,
    textAlign: 'center',
  };

  const countTitle = {
    fontSize: '0.9rem',
    textTransform: 'uppercase',
    fontWeight: '600',
    width: '100%',
  };

  const maritalStatusChartData = {
    labels: maritalStatusLabels,
    datasets: [
      {
        label: 'User Martial Status Distribution',
        data: maritalStatusData,
        strokeColor: theme.colors.transparent,
        borderColor: theme.colors.transparent,
        backgroundColor: function(context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value > 3
            ? theme.colors.primary // draw negative values in red
            : index % 2
              ? theme.colors.secondary // else, alternate values in blue and green
              : theme.colors.secondary;
        },
      },
    ],
  };

  const ageChartData = {
    labels: ageLabels,
    datasets: [
      {
        label: 'Age Distribution',
        data: ageData,
        strokeColor: theme.colors.transparent,
        borderColor: theme.colors.transparent,
        backgroundColor: function(context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value > 3
            ? theme.colors.primary // draw negative values in red
            : index % 2
              ? theme.colors.secondary // else, alternate values in blue and green
              : theme.colors.secondary;
        },
      },
    ],
  };

  const childrenChartData = {
    labels: childrenLabels,
    datasets: [
      {
        label: 'Number of Children by User',
        data: childrenData,
        strokeColor: theme.colors.transparent,
        borderColor: theme.colors.transparent,
        backgroundColor: function(context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value > 3
            ? theme.colors.primary // draw negative values in red
            : index % 2
              ? theme.colors.secondary // else, alternate values in blue and green
              : theme.colors.secondary;
        },
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            fontSize: 14,
            display: true,
            beginAtZero: true,
            precision: 0,
          },
        },
      ],
      xAxes: [
        {
          display: true,
          categoryPercentage: 0.95,
          barPercentage: 0.95,
          gridLines: {
            color: theme.colors.transparent,
          },
          ticks: {
            fontSize: 14,
            display: true,
            beginAtZero: true,
          },
        },
      ],
    },
    layout: {
      padding: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
    },
    hover: {
      intersect: false,
    },
    tooltips: {
      enabled: false,
      intersect: false,
    },
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Joined',
      dataIndex: 'count',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.count - b.count,
    },
  ];

  const rangeConfig = {
    rules: [{ type: 'array', required: true, message: 'Please select date!' }],
  };

  return (
    <>
      <Flex flexDirection="row">
        <Box w={[3 / 4]} mr={[2, 3]}>
          <Heading>Demographics</Heading>
        </Box>
        <Box w={[1 / 4]}>
          <Form onSubmit={downloadCSV}>
            <Form.Item>{getFieldDecorator('range-picker', rangeConfig)(<RangePicker />)}</Form.Item>
            <Button minWidth="1rem" size="small">
              Export CSV
            </Button>
          </Form>
        </Box>
      </Flex>
      <Container maxWidth={72} px={[2, 3, 4]} mb={[2, 3]} flexDirection="column" flexAlign="start">
        <Box p={[2, 3, 4]}>
          <Flex justifyContent="center" style={countNum}>{`${total_users}`}</Flex>
          <Flex justifyContent="center" style={countTitle}>
            Users
          </Flex>
        </Box>
      </Container>
      <Container maxWidth={72} px={[2, 3, 4]} mb={[2, 3]} flexDirection="column" flexAlign="start">
        <Box mb={[2, 3]}>
          <Subtitle>Users by Marital Status</Subtitle>
        </Box>
        <Bar data={maritalStatusChartData} width={100} height={50} options={options} />
      </Container>
      <Container maxWidth={72} px={[2, 3, 4]} mb={[2, 3]} flexDirection="column" flexAlign="start">
        <Box mt={[4, 5]} mb={[2, 3]}>
          <Subtitle>Users by Age</Subtitle>
        </Box>
        <Bar data={ageChartData} width={100} height={50} options={options} />
      </Container>
      <Container maxWidth={72} px={[2, 3, 4]} mb={[2, 3]} flexDirection="column" flexAlign="start">
        <Box mt={[4, 5]} mb={[2, 3]}>
          <Subtitle>Number of Children by User</Subtitle>
        </Box>
        <Bar data={childrenChartData} width={100} height={50} options={options} />
      </Container>
      <Container maxWidth={72} px={[2, 3, 4]} mb={[2, 3]} flexDirection="column" flexAlign="start">
        <Box mt={[4, 5]} mb={[2, 3]}>
          <Subtitle>Users by Postal Code</Subtitle>
        </Box>
        <Table
          columns={columns}
          dataSource={postalCodeData}
          pagination={{ defaultPageSize: 20 }}
          bordered
        />
      </Container>
    </>
  );
};

export default Form.create()(Demographics);
