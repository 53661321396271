import React, { useState, useContext } from 'react';
import useForm from 'react-hook-form';
import { createBrowserHistory } from 'history';
import { Form, Input } from 'antd';
import { Box, Button, Flex, Subtitle, Text, Title, Container } from './common';
import { postRequest } from '../utils/network';
import { BASE_URL } from '../utils/constants';
import { UserContext } from '../contexts/UserContext';
import { isTokenValid } from '../utils/auth';
import { TOKEN } from '../utils/constants';

const history = createBrowserHistory({
  forceRefresh: true,
});

const SignupForm = props => {
  const { form, form: { getFieldDecorator } } = props;
  const [user, setUser] = useContext(UserContext);
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit = data => {
    const preppedData = {
      ...data,
    };
    setErrorMsg('');
    form.validateFields((err, preppedData) => {
      if (err) {
        return;
      }
      postRequest(`${BASE_URL}/users`, preppedData)
        .then(response => {
          const token = response.headers[TOKEN];
          let loggedIn = false;

          if (isTokenValid(token)) {
            localStorage.setItem(TOKEN, token);
            loggedIn = true;
          }

          return postRequest('/data', {})
            .then(response => {
              console.log('data clear success:', response);

              setSuccess(true);
              setUser({
                ...user,
                userData: {},
                loggedIn,
              });
              history.push('/questions');
            })
            .catch(err => {
              console.error('Could not erase data from the backend.', err);
            });
        })
        .catch(error => {
          setErrorMsg(
            'An error occurred while trying to create your account. Please try again or contact support.',
          );
        });
    });
  };

  const handleConfirmBlur = e => {
    const { val } = e.target;
    setConfirmDirty(confirmDirty || !!val);
  };

  const validateConfirmPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['password_confirm'], { force: true });
    }
    callback();
  };

  const compareConfirmToPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Password confirmation and password must match');
    } else {
      callback();
    }
  };

  const compareConfirmToEmail = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('email')) {
      callback('Email confirmation and email must match');
    } else {
      callback();
    }
  };

  const passwordReqText = 'Password must contain at least 8 characters.';
  const refCodeText = 'If you have a referral code, enter it here.';
  // regex found http://regexlib.com/Search.aspx?k=email
  const emailRegex = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i;

  const errorMessage = {
    color: 'red',
    fontSize: '0.875rem',
  };

  const subtleMessage = {
    color: 'grey',
    fontSize: '0.875rem',
    marginBottom: '0.5rem',
    textAlign: 'center',
  };

  const prequalSuccessMessage1of2 = 'Willingworth’s Complete Will Package works for you!';

  const prequalSuccessMessage2of2 = 'Create a login below to proceed to the questionnaire.';

  const signupForm = () => (
    <>
      <Flex minHeight={5} pt={3} alignItems="center" justifyContent="center">
        <Box>
          <Title style={{ textAlign: 'center', lineHeight: 2 }}>
            {prequalSuccessMessage1of2}
            <br />
            {prequalSuccessMessage2of2}
          </Title>
        </Box>
      </Flex>
      <Container maxWidth={28} px={[2, 3, 4]} flexDirection="column">
        <Subtitle>Login Signup</Subtitle>
        <Box mb={2}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Item>
              {getFieldDecorator('first_name', {
                rules: [{ required: true, message: 'First name is required' }],
              })(<Input size="large" type="text" placeholder="First Name" ref={register({})} />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('last_name', {
                rules: [{ required: true, message: 'Last name is required' }],
              })(<Input size="large" type="text" placeholder="Last Name" ref={register({})} />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'Must be an email address' },
                ],
              })(<Input size="large" type="text" placeholder="Email" ref={register({})} />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('email_confirm', {
                rules: [
                  { required: true, message: 'Email address confirmation and email must match' },
                  { validator: compareConfirmToEmail },
                ],
              })(
                <Input
                  size="large"
                  type="text"
                  placeholder="Email Confirmation"
                  ref={register({})}
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'Password is required' },
                  { min: 8, message: 'Must be at least 8 characters in length' },
                  { validator: validateConfirmPassword },
                ],
              })(
                <Input.Password
                  size="large"
                  type="password"
                  placeholder="Password"
                  ref={register({})}
                  onBlur={handleConfirmBlur}
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password_confirm', {
                rules: [
                  { required: true, message: 'Password confirmation and password must match' },
                  { validator: compareConfirmToPassword },
                ],
              })(
                <Input.Password
                  size="large"
                  type="password"
                  placeholder="Password Confirmation"
                  ref={register({})}
                />,
              )}
            </Form.Item>
            <Button style={{ width: '100%' }} htmlType="submit">
              Submit
            </Button>
          </Form>
        </Box>
      </Container>
    </>
  );

  return (
    <>
      <p style={errorMessage}>{errorMsg}</p>
      {signupForm()}
    </>
  );
};

export default Form.create()(SignupForm);
