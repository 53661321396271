import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Alert } from 'antd';
import { Button, Title, Loader } from './common';
import OrganizationForm from './OrganizationForm';
import { putRequest, getRequest } from '../utils/network';
import { notificationRedirect } from '../utils/helpers';

const history = createBrowserHistory({
  forceRefresh: true,
});

const EditOrganization = props => {
  const [isLoading, setLoading] = useState(true);
  const [org, setOrg] = useState(null);
  const [error, setError] = useState('');
  const { orgid } = useParams();

  useEffect(
    () => {
      setError('');
      getRequest(`/organizations/${orgid}`)
        .then(({ data }) => {
          setOrg(data);
          setLoading(false);
        })
        .catch(() => {
          setError(
            'There was an error loading the organization. Reload the page to try loading again.',
          );
        });
    },
    [orgid],
  );

  const handleSubmit = data => {
    setError('');
    putRequest(`/organizations/${orgid}`, data).then(({ data }) => {
      try {
        notificationRedirect('/organizations', orgid);
      } catch (error) {
        console.log(error);
        setError(
          'There was an error saving the organization. Please check the values and try again.',
        );
      }
    });
  };

  return (
    <>
      <Title>Edit Organization</Title>
      {error === '' ? null : <Alert message={error} type="error" showIcon />}
      {isLoading ? (
        <Loader style={{ height: '80vh' }} />
      ) : (
        <>
          <OrganizationForm {...props} handleSubmit={handleSubmit} orgData={org} />
        </>
      )}
    </>
  );
};

export default EditOrganization;
