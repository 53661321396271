import React, { useState } from 'react';
import { Input, Form, Result } from 'antd';
import { Box, Button, Flex, Subtitle, Title } from '../components/common';
import Question from '../components/Question';
import SignupForm from '../components/SignupForm';
import { postRequest } from '../utils/network';

const questions = {
  ageCheck: {
    text: 'Are you 18 or older?',
    options: ['Yes', 'No'],
    optionFlow: { Yes: 'marriageCheck', No: 'exit' },
    exitResponse: 'Please come back to make your will when you turn 18.',
    questionID: 'ageCheck',
  },
  marriageCheck: {
    text: 'Have you been married more than once?',
    options: ['Yes', 'No'],
    optionFlow: { Yes: 'multiChildCheck', No: 'childrenCheck' },
    exitResponse:
      'We would love to help you, but your family circumstances are complex and will likely require the services of an estate planning attorney in your area.',
    questionID: 'marriageCheck',
  },
  multiChildCheck: {
    text: 'Do you have children with more than one partner?',
    options: ['Yes', 'No'],
    optionFlow: { Yes: 'exit', No: 'childrenCheck' },
    exitResponse:
      'We would love to help you, but your family circumstances are complex and will likely require the services of an estate planning attorney in your area.',
    questionID: 'marriageCheck',
  },
  childrenCheck: {
    text: 'Do any of your children have special needs?',
    options: ['Yes', 'No'],
    optionFlow: { Yes: 'exit', No: 'success' },
    exitResponse:
      'We would love to help you, but our document package does not currently offer a special needs trust. We are happy to email you as soon as this document is added to the package or you can contact an estate planning attorney in your area.',
    questionID: 'childrenCheck',
  },
};

const PrequalQuestionsContainer = props => {
  const { form, form: { getFieldDecorator } } = props;
  const [success, setSuccess] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState('ageCheck');
  const [exited, setExited] = useState(false);

  const displayQuestion = questions[currentQuestion];

  const handleSubmit = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        postRequest('/pq_emails', {
          ...values,
          question: displayQuestion.questionID,
        }).then(resp => setSuccess(true));
      }
    });
  };

  const questionAnswered = e => {
    const nextFlow = displayQuestion.optionFlow[e.target.value];
    if (nextFlow == 'exit') {
      postRequest('/activities', {
        type: 'prequalFail',
        value: displayQuestion.questionID,
      });
      setExited(true);
      return;
    }

    setCurrentQuestion(nextFlow);

    if (nextFlow == 'success') {
      postRequest('/activities', {
        type: 'prequalSuccess',
        value: 'success',
      });

      return;
    }
  };

  const failedPqDisplay = () => (
    <>
      <Box maxWidth="60rem" width={1} m="auto">
        <Flex minHeight={5} flexDirection="column" alignItems="center" justifyContent="center">
          <Title fontSize="1.2rem">{displayQuestion.exitResponse}</Title>
        </Flex>
      </Box>
      <Box maxWidth="28rem" width={1} mt="1rem" px={[3, 4]}>
        <Subtitle>Would you like to receive updates?</Subtitle>
        <Form onSubmit={handleSubmit}>
          <Flex alignItems="center" justifyContent="center">
            <Box width={['auto', 3 / 4]} mr={[2, 0]}>
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Email is required' }],
              })(<Input size="large" type="text" placeholder="Email" />)}
            </Box>
            <Box width={['auto', 1 / 4]}>
              <Button minWidth="auto">Submit</Button>
            </Box>
          </Flex>
        </Form>
      </Box>
    </>
  );

  const subscribeSuccess = () => (
    <Result status="success" title="Thanks! You have successfully been added to our email list." />
  );

  const questionOrSuccess = () =>
    currentQuestion === 'success' ? (
      <SignupForm />
    ) : (
      <Question
        key={displayQuestion.text}
        question={displayQuestion}
        handlePressed={questionAnswered}
      />
    );

  const display = () =>
    exited ? (success ? subscribeSuccess() : failedPqDisplay()) : questionOrSuccess();

  return display(), <div className="App-header">{display()}</div>;
};

export default Form.create()(PrequalQuestionsContainer);
