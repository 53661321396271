import React from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import { Select, Row } from 'antd';
import { NameFields, NameKeyPropTypes } from './NameType';
import { Box, Button, Subtitle } from '../common';
import { safeParseInt, fieldName } from '../../utils/helpers';
import { QuestionTypePropTypes } from './QuestionPropTypes';
import { monthsOptionMap } from '../../utils/constants';
import { SelectField } from './SelectType';
import PrevNextBtnGroup from '../PrevNextBtnGroup';

function NameBirthdateTypeQuestion(props) {
  const { register, handleSubmit, errors } = useForm();
  const { question, keyData, onSubmit, handlePrevious } = props;

  const nameData = keyData.name || {
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: null,
  };

  const yearOptions = [];
  for (let year = 1900; year <= new Date().getFullYear(); year += 1) {
    yearOptions.push(
      <Select.Option key={year} value={year}>
        {year}
      </Select.Option>,
    );
  }

  const prepData = data => {
    const { firstName, middleName, lastName, suffix } = data;
    const year = data['year-select'];
    const month = data['month-select'];
    const day = data['day-select'];
    const dateData = [safeParseInt(year), safeParseInt(month), safeParseInt(day)];
    const bday = new Date(...dateData);

    return {
      question,
      response: {
        name: {
          firstName,
          middleName,
          lastName,
          suffix,
        },
        birthdate: bday,
      },
    };
  };

  const submitHandler = data => {
    onSubmit(prepData(data));
  };

  const birthdate = new Date(Date.parse(keyData.birthdate || ''));

  return (
    <>
      <NameFields register={register} errors={errors} questionValues={nameData} />
      <Subtitle>Birthdate</Subtitle>
      <DateFields register={register} date={birthdate} />
      <br />
      <PrevNextBtnGroup handlePrevious={handlePrevious} />
    </>
  );
}

export const DateFields = props => {
  const { date, namePrefix, form } = props;

  const birthdate = new Date(Date.parse(date));

  const day = fieldName(namePrefix, 'day');
  const month = fieldName(namePrefix, 'month');
  const year = fieldName(namePrefix, 'year');

  const yearOptions = {};
  for (let yearOpt = 1900; yearOpt <= new Date().getFullYear(); yearOpt += 1) {
    yearOptions[yearOpt] = {
      display: `${yearOpt}`,
    };
  }

  const dateOptions = {};
  for (let date = 1; date <= 31; date++) {
    dateOptions[date] = {
      display: `${date}`,
    };
  }

  const defaultMonth =
    birthdate.getMonth() == undefined || Number.isNaN(birthdate.getMonth())
      ? undefined
      : `${birthdate.getMonth()}`;

  const defaultDay =
    birthdate.getDate() == undefined || Number.isNaN(birthdate.getDate())
      ? ''
      : `${birthdate.getDate()}`;

  return (
    <>
      <SelectField
        defaultValue={defaultMonth}
        optionList={monthsOptionMap}
        namePrefix={month}
        emptyDisplay="Month"
        form={form}
        isRequired
      />
      <SelectField
        defaultValue={defaultDay}
        optionList={dateOptions}
        namePrefix={day}
        emptyDisplay="Day"
        form={form}
        isRequired
      />
      <SelectField
        defaultValue={`${birthdate.getFullYear()}`}
        optionList={yearOptions}
        namePrefix={year}
        emptyDisplay="Year"
        form={form}
        isRequired
      />
    </>
  );
};

DateFields.propTypes = {
  // date: PropTypes.instanceOf(Date).isRequired,
  date: PropTypes.string,
  namePrefix: PropTypes.string,
};

DateFields.defaultProps = {
  namePrefix: '',
};

export const NameBirthdateKeyPropTypes = PropTypes.shape({
  name: NameKeyPropTypes.isRequired,
  birthdate: PropTypes.string.isRequired,
});

NameBirthdateTypeQuestion.propTypes = {
  ...QuestionTypePropTypes,
  // keyData: NameBirthdateKeyPropTypes.isRequired,
};

export default NameBirthdateTypeQuestion;

export const exampleNameBirthdateTypeQuestion = {
  question: {
    ID: 'example-nameBirthdateType-id',
    questionID: 1,
    question: 'What is your name and date of birth?',
    nextQuestionID: 2,
    keyID: 'example-nameBirthdateType-keyID',
    select: null,
    type: 'NameBirthdate',
  },
  keyData: {
    name: {
      firstName: 'example-firstName',
      middleName: 'example-middleName',
      lastName: 'example-lastName',
      suffix: null,
    },
    birthdate: '1980-11-16T05:00:00.000Z',
  },
};
