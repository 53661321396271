import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space, height, width } from 'styled-system';
import Link from './Link';
import logo from '../../logo.png';

const propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

const Image = styled.img`
  ${space}
  ${width}
  ${height}
`;

Image.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...height.propTypes,
};

function Logo({ children, onClick, height, width, ...props }, ref) {
  return (
    <Link
      className="header__logo"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      children={children}
      space={space}
    >
      <Image src={logo} alt="logo" className="logo" />
    </Link>
  );
}

Logo.propTypes = propTypes;

export default Logo;
