import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Form, Row } from 'antd';
import { NameFields, NameKeyPropTypes } from './NameType';
import { relationshipTypes, relationshipMap, relationshipMapStatus } from '../../utils/constants';
import { Flex, Button, Box } from '../common';
import { SelectField, selectFieldValue } from './SelectType';
import { QuestionTypePropTypes } from './QuestionPropTypes';
import { safeParseFloat, relationshipNameKey } from '../../utils/helpers';
import { UserContext, userRelationshipMap } from '../../contexts/UserContext';
import PrevNextBtnGroup from '../PrevNextBtnGroup';

function RelationshipPercentTypeQuestion(props) {
  const defaultValues = {
    name: { firstName: '', middleName: '', lastName: '', suffix: null },
    percent: 1,
    relationship: '',
  };

  const [user, setUser] = useContext(UserContext);
  const { question, keyData, onSubmit, form, form: { getFieldDecorator }, handlePrevious } = props;
  const [rels, setRels] = useState(keyData.relationshipPercentList || [defaultValues]);
  const [percents, setPercents] = useState(rels.map(rel => rel.percent) || []);
  const [existingRel, setExistingRel] = useState(false);
  let formIndex = 0;

  function onChange(value) {
    console.log('changed', value);
  }

  const initialKeys = () => {
    formIndex = rels.length - 1;
    return rels.map((value, idx) => idx);
  };

  const prepData = data => {
    const response = { relationshipPercentList: [] };
    const relationships = [];
    for (let idx = 0; idx < rels.length; idx += 1) {
      var relationshipValue = selectFieldValue(data, `${idx}-relationship`);
      if (Object.keys(user.userData.relationships).includes(relationshipValue)) {
        relationshipValue = user.userData.relationships[relationshipValue].relationship;
      }

      const updatedValues = {
        name: {
          firstName: data[`${idx}-firstName`],
          middleName: data[`${idx}-middleName`],
          lastName: data[`${idx}-lastName`],
          suffix: data[`${idx}-suffix`],
        },
        percent: safeParseFloat(data[`${idx}-percent`]),
        relationship: relationshipValue,
      };
      response.relationshipPercentList[idx] = updatedValues;
      relationships[idx] = {
        name: updatedValues.name,
        relationship: updatedValues.relationship,
        key: relationshipNameKey(updatedValues.name, updatedValues.relationship),
      };
    }
    return {
      question,
      response,
      relationships,
    };
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err && percentTotal() === 100) {
        onSubmit(prepData(values));
      }
    });
  };

  const add = ev => {
    ev.preventDefault();
    const updatedRels = [...rels, defaultValues];
    setRels(updatedRels);

    const updatedPercents = [...percents, 0];
    setPercents(updatedPercents);

    const keys = form.getFieldValue('keys');
    formIndex += 1;
    form.setFieldsValue({ keys: keys.concat(formIndex) });
  };

  const remove = k => {
    const keys = form.getFieldValue('keys');
    if (keys.length === 1) {
      return;
    }

    const updatedRels = rels;
    updatedRels.splice(k, 1);
    setRels(updatedRels);

    const updatedPercents = percents;
    updatedPercents.splice(k, 1);
    setPercents(updatedPercents);

    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  const updatePercents = (idx, value) => {
    const updatedPercents = [...percents];
    updatedPercents[idx] = value;
    setPercents(updatedPercents);
  };

  const updateRelationships = (idx, value) => {
    const updatedRels = [...rels];
    updatedRels[idx] = value;
    setRels(updatedRels);
  };

  const percentTotal = () => percents.reduce((acc, val) => acc + val, 0);

  getFieldDecorator('keys', { initialValue: initialKeys() });
  const keys = form.getFieldValue('keys');
  const items = keys.map((k, idx) => {
    const rel = rels[k];
    return (
      <Box mt={[2, 3]} mb={[3, 4]} key={idx}>
        <RelationshipPercentFields
          form={form}
          idx={k}
          rel={rel}
          userData={user.userData}
          relationships={user.userData.relationships}
          updateRels={updateRelationships}
          updatePercents={updatePercents}
        />
        <Row type="flex" justify="center">
          <Button m={2} type="button" onClick={() => remove(idx)} name={`${idx}`}>
            Remove
          </Button>
        </Row>
      </Box>
    );
  });

  return (
    <Form onSubmit={handleSubmit}>
      {items}
      <Flex py={3} alignItems="center" justifyContent="center">
        <h3>Current Total: {percentTotal()}%</h3>
      </Flex>
      {percentTotal() !== 100 ? (
        <Flex py={3} alignItems="center" justifyContent="center">
          <h3>Total Must Equal 100%</h3>
        </Flex>
      ) : null}

      <Flex py={3} alignItems="center" flexDirection="column" justifyContent="center">
        <Button variant="primary" type="button" onClick={add}>
          Add Additional Recipient
        </Button>
      </Flex>
      <PrevNextBtnGroup handlePrevious={handlePrevious} />
    </Form>
  );
}

export const RelationshipPercentTypeDisplay = props => {
  const { data: { relationshipPercentList: list } } = props;
  return list.map(item => {
    const name = item.name;
    return `${item.percent}% to ${name.firstName} ${name.middleName} ${
      name.lastName
    } ${name.suffix || ''}, ${item.relationship}`;
  });
};

const RelationshipPercentKeyPropTypes = PropTypes.shape({
  name: NameKeyPropTypes,
  percent: PropTypes.number,
  relationship: PropTypes.oneOf(relationshipTypes),
});

RelationshipPercentTypeQuestion.propTypes = {
  ...QuestionTypePropTypes,
  keyData: PropTypes.objectOf(PropTypes.arrayOf(RelationshipPercentKeyPropTypes)).isRequired,
};

export default Form.create()(RelationshipPercentTypeQuestion);

export const exampleRelationshipPercentTypeQuestion = {
  question: {
    ID: 'example-relationshipPercentType-id',
    questionID: 1,
    question: 'How do you want to distribute your property?',
    nextQuestionID: 2,
    keyID: 'example-relationshipPercentType-keyID',
    select: null,
    type: 'RelationshipPercent',
  },
  keyData: {
    relationshipPercentList: [
      {
        name: {
          firstName: 'example-firstName',
          middleName: 'example-middleName',
          lastName: 'example-lastName',
          suffix: null,
        },
        percent: 56.7,
        relationship: 'Grandparent',
      },
      {
        name: {
          firstName: 'example-firstName',
          middleName: 'example-middleName',
          lastName: 'example-lastName',
          suffix: null,
        },
        percent: 12.3,
        relationship: 'Brother',
      },
      {
        name: {
          firstName: 'example-firstName',
          middleName: 'example-middleName',
          lastName: 'example-lastName',
          suffix: null,
        },
        percent: 31.0,
        relationship: 'Spouse',
      },
    ],
  },
};

const RelationshipPercentFields = props => {
  const {
    form,
    form: { getFieldDecorator },
    idx,
    rel,
    userData,
    updateRels,
    updatePercents,
  } = props;
  const [disabled, setDisabled] = useState(false);

  const relationshipOptions = {
    ...userRelationshipMap(userData.relationships),
    ...relationshipMapStatus(userData.responses['relationship_status']),
  };

  const loadRelationship = (idx, value) => {
    const existingRelationship = userData.relationships[value];
    if (existingRelationship != undefined) {
      updateRels(idx, existingRelationship);
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <>
      <NameFields questionValues={rel.name} namePrefix={`${idx}`} form={form} disabled={disabled} />
      <br />
      <SelectField
        defaultValue={rel.relationship}
        namePrefix={`${idx}-relationship`}
        optionList={relationshipOptions}
        form={form}
        onChange={value => loadRelationship(idx, value)}
        isRequired
      />
      <Row type="flex" justify="center">
        <Form.Item>
          {getFieldDecorator(`${idx}-percent`, {
            initialValue: rel.percent || 0,
          })(
            <InputNumber
              size="large"
              min={0}
              max={100}
              formatter={value => `${value}%`}
              parser={value => value.replace('%', '')}
              onChange={value => {
                updatePercents(idx, value);
              }}
            />,
          )}
        </Form.Item>
      </Row>
    </>
  );
};
