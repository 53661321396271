import React from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';

import { Select, Form, Input, Row } from 'antd';
import { QuestionTypePropTypes, QuestionTypeDefaultProps } from './QuestionPropTypes';
import { states } from '../../utils/constants';
import { Box, Button } from '../common';
import PrevNextBtnGroup from '../PrevNextBtnGroup';

const StateTypeQuestion = props => {
  const {
    question,
    keyData,
    onSubmit,
    form,
    form: { getFieldDecorator },
    handlePrevious,
  } = props;

  // do any data manipulation before passing off to onSubmit
  const prepData = data => {
    return {
      question,
      response: data,
    };
  };

  // handle submission from form
  const submitHandler = data => {
    onSubmit(prepData(data));
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        onSubmit(prepData(values));
      }
    });
    // console.log(e);
  };

  const stateSelectOptions = () =>
    Object.keys(states).map(k => (
      <Select.Option key={k} value={k}>
        {states[k]}
      </Select.Option>
    ));

  return (
    <Form onSubmit={handleSubmit}>
      <Box my={[4, 5]}>
        <Row type="flex" justify="center">
          <Form.Item>
            {getFieldDecorator('state', {
              initialValue: keyData.state,
              rules: [{ required: true, message: 'Please select a state' }],
            })(<Select size="large">{stateSelectOptions()}</Select>)}
          </Form.Item>
        </Row>
      </Box>
      <PrevNextBtnGroup handlePrevious={handlePrevious} />
    </Form>
  );
};

export const StateTypeDisplay = props => {
  const {
    data: { state },
  } = props;

  return state;
};

const StateKeyPropTypes = PropTypes.shape({
  state: PropTypes.oneOf(Object.keys(states)),
});

StateTypeQuestion.propTyes = {
  ...QuestionTypePropTypes,
  keyData: PropTypes.objectOf(StateKeyPropTypes.isRequired).isRequired,
};
StateTypeQuestion.defaultProps = QuestionTypeDefaultProps;

export default Form.create()(StateTypeQuestion);

export const exampleStateTypeQuestion = {
  question: {
    ID: 'example-statetype-id',
    questionID: 1,
    question: 'Which state do you live in?',
    nextQuestionID: 1,
    keyID: 'example-statetype-keyID',
    select: null,
    type: 'State',
  },
  keyData: {
    state: 'MS',
  },
};
