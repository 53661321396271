import styled from 'styled-components';
import { color, fontFamily, fontWeight, space, maxWidth } from 'styled-system';
import theme, { mediaQueries } from './theme';

const Heading = styled.h1`
    ${color}
    ${space}
    ${fontWeight}
    ${fontFamily}
    ${maxWidth}

    & {
      font-size: 1.2rem;
      letter-spacing: -0.0025rem;
      line-height: 1.4;
      text-align: center;
      max-width: 640px;

      ${theme.mediaQueries.md} {
        font-size: 1.4rem;
      }

      ${theme.mediaQueries.lg} {
        font-size: 1.6rem;
      }
    }
`;

Heading.defaultProps = {
  theme,
  color: theme.colors.secondary,
  fontWeight: 400,
  fontFamily: theme.fonts.sansSerifAlt,
  mx: 'auto',
};

Heading.displayName = 'Heading';

export default Heading;
