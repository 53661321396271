import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { QuestionTypePropTypes, QuestionTypeDefaultProps } from './QuestionPropTypes';
import { Box, Subtitle } from '../common';
import { NameFields } from './NameType';
import { SelectField, selectFieldValue } from './SelectType';
import { relationshipNameKey, fieldName } from '../../utils/helpers';
import { relationshipMapStatus } from '../../utils/constants';
import { UserContext, userRelationshipMap } from '../../contexts/UserContext';
import PrevNextBtnGroup from '../PrevNextBtnGroup';

const PBPrimaryTypeQuestion = props => {
  const [user] = useContext(UserContext);
  const { question, keyData, onSubmit, form, handlePrevious } = props;

  // do any data manipulation before passing off to onSubmit
  const prepData = data => {
    const relKeys = ['primary', 'backup'];

    const response = {};
    let relationships = [];
    relKeys.forEach(key => {
      var relValue = selectFieldValue(data, key);
      if (Object.keys(user.userData.relationships).includes(relValue)) {
        relValue = user.userData.relationships[relValue].relationship;
      }

      const respMap = {
        name: {
          firstName: data[`${key}-firstName`],
          middleName: data[`${key}-middleName`] || '',
          lastName: data[`${key}-lastName`],
          suffix: data[`${key}-suffix`] || '',
        },
        relationship: relValue,
      };
      response[key] = respMap;
      relationships = [
        ...relationships,
        { ...respMap, key: relationshipNameKey(respMap.name, relValue) },
      ];
    });

    return {
      question,
      response,
      relationships,
    };
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(prepData(values));
      }
    });
  };

  return (
    <>
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <PBGroup keyData={keyData} form={form} user={user} qid={question.questionID} />
          <PrevNextBtnGroup handlePrevious={handlePrevious} />
        </Form>
      </React.Fragment>
    </>
  );
};

export const PBPrimaryTypeDisplay = props => {
  const { data: { primary, backup } } = props;
  const primaryDetails = `${primary.name.firstName} ${primary.name.middleName} ${
    primary.name.lastName
  } ${primary.name.suffix || ''} (${primary.relationship})`;
  const backupDetails = `${backup.name.firstName} ${backup.name.middleName} ${
    backup.name.lastName
  } ${backup.name.suffix || ''} (${backup.relationship})`;
  return `Primary:    ${primaryDetails}
Backup:     ${backupDetails}`;
};

export const PBGroup = props => {
  const { keyData, form, namePrefix = '', user, name = '', qid } = props;

  const relationships = user.userData.relationships;

  const relationshipOptions = {
    ...userRelationshipMap(relationships),
    ...relationshipMapStatus(user.userData.responses['relationship_status']),
  };

  const defaultFieldKeyData = {
    name: {
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: null,
    },
    relationship: '',
  };

  return (
    <>
      {name === '' ? null : (
        <Box mb={[2, 3]}>
          <Subtitle>{name}</Subtitle>
        </Box>
      )}
      <PBFields
        title="Primary"
        keyData={keyData.primary || defaultFieldKeyData}
        form={form}
        prefix={fieldName(namePrefix, 'primary')}
        relationships={relationships}
        relationshipOptions={relationshipOptions}
        qid={qid}
      />
      <PBFields
        title="Backup"
        keyData={keyData.backup || defaultFieldKeyData}
        form={form}
        prefix={fieldName(namePrefix, 'backup')}
        relationships={relationships}
        relationshipOptions={relationshipOptions}
        qid={qid}
      />
    </>
  );
};

export const PBFields = props => {
  const {
    title,
    form,
    keyData,
    prefix,
    relationships,
    relationshipOptions,
    qid,
    skipReset,
  } = props;

  const [nameKeyData, setNameKeyData] = useState(keyData.name);
  const relationshipKeyData = keyData.relationship;
  const [existingRelationship, setExistingRelationship] = useState(false);

  const loadRelationship = value => {
    const existing = relationships[value];
    if (existing != undefined) {
      setNameKeyData(existing.name);
      form.resetFields([firstName, middleName, lastName, suffix]);
      setExistingRelationship(true);
    } else {
      setExistingRelationship(false);
    }
  };

  const firstName = fieldName(prefix, 'firstName');
  const middleName = fieldName(prefix, 'middleName');
  const lastName = fieldName(prefix, 'lastName');
  const suffix = fieldName(prefix, 'suffix');

  useEffect(
    () => {
      if (!skipReset) {
        form.resetFields();
        setNameKeyData(keyData.name);
        setExistingRelationship(false);
      }
    },
    [qid],
  );

  return (
    <>
      <Box mb={[2, 3]}>
        <Subtitle>{title}</Subtitle>
      </Box>
      <Box>
        <SelectField
          optionList={relationshipOptions}
          defaultValue={relationshipKeyData}
          namePrefix={prefix}
          form={form}
          onChange={loadRelationship}
          isRequired
        />
        <NameFields
          questionValues={nameKeyData}
          namePrefix={prefix}
          form={form}
          disabled={existingRelationship}
        />
      </Box>
    </>
  );
};

export const PBPrimaryKeyPropTypes = PropTypes.shape({
  // props for key data goes here
});

PBPrimaryTypeQuestion.propTypes = {
  ...QuestionTypePropTypes,
  keyData: PropTypes.objectOf(PBPrimaryKeyPropTypes.isRequired).isRequired,
};
PBPrimaryTypeQuestion.defaultProps = QuestionTypeDefaultProps;

export default Form.create()(PBPrimaryTypeQuestion);

export const examplePBPrimaryTypeQuestion = {
  question: {
    ID: 'example-pbprimary-id',
    questionID: 1,
    question: 'Who are the executors?',
    nextQuestionID: 2,
    keyID: 'example-pbprimary-keyID',
    select: {
      primary: {
        Mother: {
          display: 'Mother',
          nextQuestion: 3,
        },
        Father: {
          display: 'Father',
          nextQuestion: 4,
        },
        Sister: {
          display: 'Sister',
          nextQuestion: 5,
        },
        Brother: {
          display: 'Brother',
          nextQuestion: 6,
        },
        Grandparent: {
          display: 'Grandparent',
          nextQuestion: 7,
        },
        Aunt: {
          display: 'Aunt',
          nextQuestion: 8,
        },
        Uncle: {
          display: 'Uncle',
          nextQuestion: 9,
        },
        Cousin: {
          display: 'Cousin',
          nextQuestion: 10,
        },
        Niece: {
          display: 'Niece',
          nextQuestion: 11,
        },
        Nephew: {
          display: 'Nephew',
          nextQuestion: 12,
        },
        'God-child': {
          display: 'God-child',
          nextQuestion: 13,
        },
        'Trusted Friend': {
          display: 'Trusted Friend',
          nextQuestion: 14,
        },
        Other: {
          display: 'Other',
          nextQuestion: 15,
        },
      },
      backup: {
        Mother: {
          display: 'Mother',
          nextQuestion: 16,
        },
        Father: {
          display: 'Father',
          nextQuestion: 17,
        },
        Sister: {
          display: 'Sister',
          nextQuestion: 18,
        },
        Brother: {
          display: 'Brother',
          nextQuestion: 19,
        },
        Grandparent: {
          display: 'Grandparent',
          nextQuestion: 20,
        },
        Aunt: {
          display: 'Aunt',
          nextQuestion: 21,
        },
        Uncle: {
          display: 'Uncle',
          nextQuestion: 22,
        },
        Cousin: {
          display: 'Cousin',
          nextQuestion: 23,
        },
        Niece: {
          display: 'Niece',
          nextQuestion: 24,
        },
        Nephew: {
          display: 'Nephew',
          nextQuestion: 25,
        },
        'God-child': {
          display: 'God-child',
          nextQuestion: 26,
        },
        'Trusted Friend': {
          display: 'Trusted Friend',
          nextQuestion: 27,
        },
        Other: {
          display: 'Other',
          nextQuestion: 28,
        },
      },
    },
    type: 'PBPrimary',
  },
  keyData: {
    primary: {
      name: {
        firstName: 'example-primary-firstName',
        middleName: 'example-primary-middleName',
        lastName: 'example-primary-lastName',
        suffix: null,
      },
      relationship: 'Mother',
    },
    backup: {
      name: {
        firstName: 'example-backup-firstName',
        middleName: 'example-backup-middleName',
        lastName: 'example-backup-lastName',
        suffix: null,
      },
      relationship: 'Uncle',
    },
  },
};
