import styled from 'styled-components';
import { color, fontFamily, fontWeight, space, fontSize } from 'styled-system';
import theme, { mediaQueries } from './theme';

const Title = styled.h1`
    ${color}
    ${space}
    ${fontSize}
    ${fontWeight}
    ${fontFamily}

    & {
      font-size: 1.25rem;
      letter-spacing: -0.05rem;
      line-height: 1.4;
      text-align: left;
      padding: 0 0.5rem;

      ${theme.mediaQueries.md} {
        font-size: calc(1.2rem + (4-2) * (100vw - 400px)/(900-400));
        padding: 0 1.5rem;
      }
    }
`;

Title.propTypes = {
  ...fontWeight.propTypes,
  ...fontSize.propTypes,
  ...space.propTypes,
  ...color.propTypes,
};

Title.defaultProps = {
  theme,
  fontWeight: 400,
  fontFamily: theme.fonts.sansSerifAlt,
  color: theme.colors.secondary,
};

Title.displayName = 'Title';

export default Title;
