import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { Box, Button, Subtitle } from '../common';
import { NameFields } from './NameType';
import { DateFields, NameBirthdateKeyPropTypes } from './NameBirthdateType';
import { QuestionPropTypes } from './QuestionPropTypes';
import { safeParseInt } from '../../utils/helpers';
import { UserContext } from '../../contexts/UserContext';
import PrevNextBtnGroup from '../PrevNextBtnGroup';
var moment = require('moment');

const childrenKey = 'children';
const testatorChildrenKey = 'testator_children';
const spouseChildrenKey = 'spouse_children';
const childrenQuestionKeys = [childrenKey, testatorChildrenKey, spouseChildrenKey];

const numChildrenKey = 'num_children';
const testatorNumChildrenKey = 'testator_num_children';
const spouseNumChildrenKey = 'spouse_num_children';
const childrenNumberKeys = [numChildrenKey, testatorNumChildrenKey, spouseNumChildrenKey];

function NameBirthdateListTypeQuestion(props) {
  const [user] = useContext(UserContext);
  const { question, keyData, onSubmit, form, handlePrevious } = props;

  const defaultValues = {
    name: {
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: null,
    },
    birthdate: new Date(),
  };

  // if question key matches childrenKey, build list length to number of children
  const defaultNamesList = () => {
    const list = keyData.nameBirthdateList || [];
    // bail if key doesn't match childrenKey or number of children isn't useful
    const keyIdx = childrenQuestionKeys.indexOf(question.keyID);
    if (
      keyIdx < 0 ||
      user == undefined ||
      user.userData == undefined ||
      user.userData.responses == undefined
    ) {
      return list;
    }

    const responses = user.userData.responses;
    const numKey = childrenNumberKeys[keyIdx];

    if (
      responses[numKey] == undefined ||
      responses[numKey]['count'] == undefined ||
      responses[numKey]['count'] <= 0
    ) {
      return list;
    }

    const count = responses[numKey]['count'];
    while (list.length < count) {
      list.push(defaultValues);
    }

    return list;
  };

  const [names, setNames] = useState(defaultNamesList());

  const prepData = data => {
    const response = { nameBirthdateList: [] };
    for (let idx = 0; idx < names.length; idx += 1) {
      const year = data[`${idx}-year-select`];
      const month = data[`${idx}-month-select`];
      const day = data[`${idx}-day-select`];
      const dateData = [safeParseInt(year), safeParseInt(month), safeParseInt(day)];
      const bday = new Date(...dateData);

      const updatedValues = {
        name: {
          firstName: data[`${idx}-firstName`],
          middleName: data[`${idx}-middleName`],
          lastName: data[`${idx}-lastName`],
          suffix: data[`${idx}-suffix`],
        },
        birthdate: bday,
      };
      response.nameBirthdateList[idx] = updatedValues;
    }

    return {
      question,
      response,
    };
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(prepData(values));
      }
    });
  };

  const addName = ev => {
    ev.preventDefault();
    const updatedNames = [...names, defaultValues];
    setNames(updatedNames);
  };

  const removeName = ev => {
    ev.preventDefault();
    const updatedNames = [...names];
    const idx = parseInt(ev.target.name, 10);
    updatedNames.splice(idx, 1);
    setNames(updatedNames);
  };

  const items = names.map((name, idx) => (
    <React.Fragment key={idx}>
      <Box py={3} alignItems="center" justifyContent="center">
        <Subtitle>
          Child {idx + 1} of {names.length}
        </Subtitle>
        <NameFields questionValues={name.name} namePrefix={`${idx}`} form={form} />
        <br />
        <DateFields date={name.birthdate} namePrefix={`${idx}`} form={form} />
      </Box>
    </React.Fragment>
  ));

  return (
    <Form onSubmit={handleSubmit}>
      {items}
      <PrevNextBtnGroup handlePrevious={handlePrevious} />
    </Form>
  );
}

export const NameBirthdateListTypeDisplay = props => {
  const {
    data: { nameBirthdateList: list },
  } = props;
  return list.map(item => {
    const name = item.name;
    const bday = item.birthdate;
    const bdayFormat = moment(bday).format('MMMM DD, YYYY');
    return `Name:           ${name.firstName} ${name.middleName} ${name.lastName} ${name.suffix ||
      ''}
Birthday:     ${bdayFormat}

`;
  });
};

NameBirthdateListTypeQuestion.propTypes = {
  ...QuestionPropTypes,
  // keyData: PropTypes.objectOf(PropTypes.arrayOf(NameBirthdateKeyPropTypes)).isRequired,
};

export default Form.create()(NameBirthdateListTypeQuestion);

export const exampleNameBirthdateListTypeQuestion = {
  question: {
    ID: 'example-nameBirthdateType-id',
    questionID: 1,
    question: 'What are the names and birthdates of your children?',
    nextQuestionID: 2,
    keyID: 'example-nameBirthdateType-keyID',
    select: null,
    type: 'NameBirthdateList',
  },
  keyData: {
    nameBirthdateList: [
      {
        name: {
          firstName: 'example-firstName',
          middleName: 'example-middleName',
          lastName: 'example-lastName',
          suffix: null,
        },
        birthdate: new Date(),
      },
      {
        name: {
          firstName: 'example-firstName',
          middleName: 'example-middleName',
          lastName: 'example-lastName',
          suffix: null,
        },
        birthdate: new Date(),
      },
      {
        name: {
          firstName: 'example-firstName',
          middleName: 'example-middleName',
          lastName: 'example-lastName',
          suffix: null,
        },
        birthdate: new Date(),
      },
    ],
  },
};
