import React from 'react';
import { Row, Col } from 'antd';
import { Box, Button } from './common';

function PrevNextBtnGroup(props) {
  const { handlePrevious, onSubmit, currentQuestion } = props;

  return (
    <>
      <Box maxWidth="325px" mx="auto" my={[4, 5]}>
        <Row type="flex" justify="space-between">
          <Col>
            <Button
              variant="primary"
              size="medium"
              width="150px"
              onClick={handlePrevious}
              style={currentQuestion === 1 ? { opacity: 0 } : null}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button variant="primary" size="medium" width="150px" onClick={onSubmit}>
              {currentQuestion === 75 ? 'Review' : 'Next'}
            </Button>
          </Col>
        </Row>
      </Box>
    </>
  );
}

export default PrevNextBtnGroup;
