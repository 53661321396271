import React, { useState } from 'react';
import { createBrowserHistory } from 'history';
import { Alert } from 'antd';
import OrganizationForm from './OrganizationForm';
import { postRequest } from '../utils/network';
import { notificationRedirect } from '../utils/helpers';
import { Title } from './common';

const history = createBrowserHistory({
  forceRefresh: true,
});

const NewOrganization = props => {
  const [error, setError] = useState('');

  const handleSubmit = data => {
    setError('');
    postRequest(`/organizations`, data).then(response => {
      const orgId = response.data.id;
      try {
        notificationRedirect('/organizations', orgId);
      } catch (error) {
        console.log(error);
        setError(
          'There was an error saving the organization. Please check the values and try again.',
        );
      }
    });
  };

  return (
    <>
      <Title>Add Organization</Title>
      {error === '' ? null : <Alert message={error} type="error" showIcon />}
      <OrganizationForm {...props} handleSubmit={handleSubmit} />
    </>
  );
};

export default NewOrganization;
