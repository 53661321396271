import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Handlebars from 'handlebars';
import { createBrowserHistory } from 'history';
import { Row, Col, Progress } from 'antd';
import {
  Box,
  Logo,
  Link,
  theme,
  SideBarSubtitle,
  Heading,
  Flex,
  ThreeQuarter,
  Quarter,
  Subtitle,
  Text,
  Loader,
} from '../common';
import CountTypeQuestion, { CountTypeDisplay } from './CountType';
import DateTypeQuestion, { DateTypeDisplay } from './DateType';
import NameTypeQuestion, { NameTypeDisplay } from './NameType';
import RelationshipTypeQuestion from './RelationshipType';
import SelectTypeQuestion, { SelectTypeDisplay } from './SelectType';
import StateTypeQuestion, { StateTypeDisplay } from './StateType';
import PBPrimaryTypeQuestion, { PBPrimaryTypeDisplay } from './PBPrimaryType';
import GuardianListTypeQuestion from './GuardianListType';
import RelationshipPercentTypeQuestion, {
  RelationshipPercentTypeDisplay,
} from './RelationshipPercentType';
import RelationshipAmountTypeQuestion from './RelationshipAmountType';
import NameBirthdateTypeQuestion from './NameBirthdateType';
import NameBirthdateListTypeQuestion, {
  NameBirthdateListTypeDisplay,
} from './NameBirthdateListType';
import CharityAmountListTypeQuestion, {
  CharityAmountListTypeDisplay,
} from './CharityAmountListType';
import StaticTypeQuestion from './StaticType';
import FinalStaticTypeQuestion from './FinalStaticType';
import { QuestionContext } from './QuestionContext';
import { postRequest, getRequest } from '../../utils/network';
import { UserContext } from '../../contexts/UserContext';
import { formatQuestion, formatReasons } from '../../utils/helpers';
import { progressQuestions } from '../../utils/constants';

const history = createBrowserHistory({
  forceRefresh: true,
});

// dynamic question type handling
export const questionTypes = {
  CharityAmountList: CharityAmountListTypeQuestion,
  Count: CountTypeQuestion,
  Date: DateTypeQuestion,
  Name: NameTypeQuestion,
  NameBirthdate: NameBirthdateTypeQuestion,
  NameBirthdateList: NameBirthdateListTypeQuestion,
  PBPrimary: PBPrimaryTypeQuestion,
  GuardianList: GuardianListTypeQuestion,
  Relationship: RelationshipTypeQuestion,
  RelationshipAmount: RelationshipAmountTypeQuestion,
  RelationshipPercent: RelationshipPercentTypeQuestion,
  Select: SelectTypeQuestion,
  State: StateTypeQuestion,
  Static: StaticTypeQuestion,
  FinalStatic: FinalStaticTypeQuestion,
};

export const displayFuncs = {
  CharityAmountList: CharityAmountListTypeDisplay,
  Count: CountTypeDisplay,
  Date: DateTypeDisplay,
  Name: NameTypeDisplay,
  NameBirthdateList: NameBirthdateListTypeDisplay,
  PBPrimary: PBPrimaryTypeDisplay,
  RelationshipPercent: RelationshipPercentTypeDisplay,
  Select: SelectTypeDisplay,
  State: StateTypeDisplay,
};

function QuestionContainer(props) {
  const [launched, setLaunched] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useContext(QuestionContext);
  const [user, setUser] = useContext(UserContext);
  const { question, keyData } = currentQuestion;
  const { qid: paramQID } = useParams();

  const prepQuestionData = data => ({
    id: data.id,
    checkChildren: data.check_children,
    keyID: data.key,
    nextQuestionID: data.next_question,
    question: data.question,
    questionID: data.qid,
    select: data.selects,
    type: data.type,
    script: data.script,
    reasons: data.reasons,
  });

  const loadExistingResponse = (responses, currentQuestion) => {
    let keyData = {};
    if (responses[currentQuestion] != undefined) {
      keyData = responses[currentQuestion];
    }
    // console.log(keyData);
    return keyData;
  };

  const loadNextQuestion = (questionData, responses) => {
    if (document.getElementById('scroll-me') != undefined) {
      document.getElementById('scroll-me').scrollTop = 0;
    }
    setCurrentQuestion({
      question: prepQuestionData(questionData),
      keyData: loadExistingResponse(responses, questionData.key),
    });
  };

  const loadPreviousQuestion = e => {
    e.preventDefault();
    const { userData } = user;
    if (userData.history === undefined) {
      userData.history = [];
    }
    const currentIdx = userData.history.indexOf(question.questionID);
    // bail if we can't go further back
    if (currentIdx == 0) {
      return;
    }
    const prevQuestionID =
      currentIdx == -1
        ? userData.history[userData.history.length - 1]
        : userData.history[currentIdx - 1];

    getRequest(`/questions/${prevQuestionID}`).then(({ data: respData }) => {
      loadNextQuestion(respData, userData.responses || {});
      setLaunched(true);
    });
  };

  const finishQuestionaire = () => {
    console.log('fin');
    props.match.params.qid != undefined
      ? history.push('/review')
      : history.push('/review?from=questions');
    // history.push('/payment');
  };

  // fetch userdata then fetch current question if not at the beginning
  useEffect(() => {
    if (!launched) {
      getRequest('/users/current').then(({ data: respData }) => {
        const fetchedUser = respData.user;
        if (
          fetchedUser.Data != undefined &&
          fetchedUser.Data.length > 0 &&
          fetchedUser.Data[0].data != undefined
        ) {
          const userData = fetchedUser.Data[0].data;
          setUser({
            ...user,
            userData: userData,
            referrer_name: fetchedUser.referrer_name || '',
          });

          let currentQuestion = userData.currentQuestion || 1;

          // if history includes param id, load that, else redirect
          if (userData.history != undefined && paramQID != undefined) {
            if (userData.history.includes(parseInt(paramQID))) {
              currentQuestion = paramQID;
            } else {
              history.push('/questions');
            }
          }

          const responses = userData.responses || {};
          if (currentQuestion > 0) {
            getRequest(`/questions/${currentQuestion}`).then(({ data: respData }) => {
              loadNextQuestion(respData, responses);
              setLaunched(true);
            });
          } else {
            setLaunched(true);
          }
        }
      });
    }
  });

  const onSubmit = data => {
    const { question: q, response, relationships } = data;

    // console.log('onSubmit:', q, response, relationships);

    if (data['type'] !== undefined && data['type'] == 'final_static') {
      finishQuestionaire();
    }

    let nextQuestion = q.nextQuestionID;

    if (q.script != undefined && q.script != '') {
      nextQuestion = eval(q.script);
    }

    postRequest('/response', {
      key: q.keyID,
      nextQuestion,
      relationships,
      response,
      currentQuestion: q.questionID,
    }).then(({ data: { question: questionData, user_data } }) => {
      const userData = user_data.data;
      setUser({
        ...user,
        userData,
      });

      const { currentQuestion, responses } = userData;
      loadNextQuestion(questionData, responses, currentQuestion);
    });
  };

  var questionContainerStyle = {
    overflow: 'auto',
  };

  const QuestionType = questionTypes[question.type];

  if (user && user.userData && user.userData.responses) {
    question.question = formatQuestion(user.userData.responses, question.question);
  }
  question.reasons = formatReasons(user, question.reasons);

  var progressIdx = 0;
  while (progressQuestions[progressIdx] <= question.questionID) {
    progressIdx += 1;
  }
  progressIdx -= 1;

  const progress = progressIdx / progressQuestions.length * 100 + 10;
  const progressStr = `Progress: Section ${progressIdx + 1} of ${progressQuestions.length}`;

  {
    /* for debugging */
  }
  const questionIdStyles = {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '75px',
    height: '75px',
    backgroundImage: 'linear-gradient(315deg, #f907fc 0%, #05d6d9 74%)',
    lineHeight: '75px',
    fontWeight: 900,
    color: 'white',
    fontSize: '3rem',
  };

  return (
    <>
      <Flex width={1} flexDirection={['column', 'column', 'row']}>
        <ThreeQuarter
          height={['60vh', '60vh', '100vh']}
          style={questionContainerStyle}
          id="scroll-me"
        >
          <Box mb={[5, 6]}>
            <Logo height="30px" onClick={() => history.push('/history')} />
            <Flex flexDirection="column" justifyContent="center" minHeight={8.5}>
              {question.type != 'FinalStatic' && question.type != 'Static' ? (
                <Box mt={[5, 4, 3]} px={[3, 2]}>
                  <Heading>{question.question}</Heading>
                </Box>
              ) : null}

              {launched ? (
                <QuestionType
                  question={question}
                  keyData={keyData}
                  onSubmit={onSubmit}
                  handlePrevious={loadPreviousQuestion}
                />
              ) : (
                <Loader />
              )}
            </Flex>
            <Progress type="line" percent={progress} showInfo={false} strokeColor="#f16f6e" />
            <Subtitle>{progressStr}</Subtitle>
          </Box>
        </ThreeQuarter>
        <Quarter height={['40vh', '40vh', '100vh']} bg={theme.colors.secondary}>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            px={[0, 3, 4]}
            py={[1, 3, 5]}
            overflow="auto"
          >
            <Box mb={[3, 4]}>
              {question.reasons != '' ? (
                <SideBarSubtitle color={theme.colors.white}>Why It Matters</SideBarSubtitle>
              ) : null}
              <Text color={theme.colors.white}>{question.reasons}</Text>
            </Box>
            <Box>
              <SideBarSubtitle color={theme.colors.white}>Still have a question?</SideBarSubtitle>
              <ul>
                <li>
                  <Link
                    href="https://www.willingworth.com/faqs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit our FAQ
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.willingworth.com/resources"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit our Glossary
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.willingworth.com/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </Box>
          </Flex>
        </Quarter>
      </Flex>
    </>
  );
}

export default QuestionContainer;

// Helpers for question script eval field
var moment = require('moment');

const SINGLE_HAS_CHILDREN_KEY = 'has_children';
const TESTATOR_HAS_CHILDREN_KEY = 'testator_has_children';
const SPOUSE_HAS_CHILDREN_KEY = 'spouse_has_children';
const BOTH_HAVE_CHILDREN_KEY = 'both_have_children';
const TESTATOR_CHILDREN_KEY = 'testator_children';
const SPOUSE_CHILDREN_KEY = 'spouse_children';
const BOTH_CHILDREN_KEY = 'children';

const minAge = childList => {
  const ages = nameBirthdateAges(childList) || [];
  return Math.min(...ages);
};

const agesOlderThan = (testAge, childList) => {
  const ages = nameBirthdateAges(childList) || [];
  const minAge = Math.min(...ages);
  return testAge < minAge;
};

// pass in response data from a nameBirthdateList Type question
const nameBirthdateAges = childList => {
  if (childList == undefined || childList.nameBirthdateList == undefined) {
    return [];
  }
  return childList.nameBirthdateList.map(item => nameBirthdateAge(item));
};

const nameBirthdateAge = nameBirthdateData =>
  moment().diff(nameBirthdateData['birthdate'], 'years'); // moment truncates to nearest integer

const boolSelectResponse = (user, key) => {
  if (user.userData.responses != undefined && user.userData.responses[key] != undefined) {
    return user.userData.responses[key]['select'] === 'Yes';
  }
  return false;
};

const testatorHasChildren = user => {
  return (
    boolSelectResponse(user, TESTATOR_HAS_CHILDREN_KEY) ||
    boolSelectResponse(user, BOTH_HAVE_CHILDREN_KEY)
  );
};

const spouseHasChildren = user => {
  return (
    boolSelectResponse(user, SPOUSE_HAS_CHILDREN_KEY) ||
    boolSelectResponse(user, BOTH_HAVE_CHILDREN_KEY)
  );
};

const bothHaveChildren = user => {
  return (
    boolSelectResponse(user, BOTH_HAVE_CHILDREN_KEY) ||
    boolSelectResponse(user, SINGLE_HAS_CHILDREN_KEY)
  );
};

const childrenCheck = (nextTogether, nextTestator, nextSpouse, nextFallback, user) => {
  if (nextTogether != -1 && bothHaveChildren(user)) {
    return nextTogether;
  } else if (nextTestator != -1 && testatorHasChildren(user)) {
    return nextTestator;
  } else if (nextSpouse != -1 && spouseHasChildren(user)) {
    return nextSpouse;
  }
  return nextFallback;
};

const minAgeForKey = (key, user) => {
  if (user.userData != undefined && user.userData.responses != undefined) {
    return minAge(user.userData.responses[key]);
  }
  return 0;
};
const testatorChildrenMinAge = user => minAgeForKey(TESTATOR_CHILDREN_KEY, user);
const spouseChildrenMinAge = user => minAgeForKey(SPOUSE_CHILDREN_KEY, user);
const bothChildrenMinAge = user => minAgeForKey(BOTH_CHILDREN_KEY, user);

const childrenAgeCheck = (age, nextTogether, nextTestator, nextSpouse, nextFallback, user) => {
  if (nextTogether != -1 && bothHaveChildren(user) && bothChildrenMinAge(user) < age) {
    return nextTogether;
  } else if (
    nextTestator != -1 &&
    testatorHasChildren(user) &&
    testatorChildrenMinAge(user) < age
  ) {
    return nextTestator;
  } else if (nextSpouse != -1 && spouseHasChildren(user) && spouseChildrenMinAge(user) < age) {
    return nextSpouse;
  }
  return nextFallback;
};
