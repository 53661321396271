import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input } from 'antd';
import { createBrowserHistory } from 'history';
import { Button, Subtitle, Heading } from './common';
import { postRequest } from '../utils/network';

const history = createBrowserHistory({
  forceRefresh: true,
});

const AccountRecovery = props => {
  const {
    form,
    form: { getFieldDecorator },
  } = props;
  const { token } = useParams();
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    setErrorMsg('');
    form.validateFields((err, values) => {
      if (!err) {
        values.token = token;
        postRequest('/recover', values)
          .then(resp => {
            setSuccess(true);
          })
          .catch(error => {
            setErrorMsg('An error occurred while setting your new password. Please try again.');
          });
      }
    });
  };

  const handleConfirmBlur = e => {
    const { val } = e.target;
    setConfirmDirty(confirmDirty || !!val);
  };

  const validateConfirmPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['password_confirm'], { force: true });
    }
    callback();
  };

  const compareConfirmToPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Password confirmation and password must match');
    } else {
      callback();
    }
  };

  const recoveryForm = () => (
    <Form onSubmit={handleSubmit}>
      <Form.Item>
        {getFieldDecorator('email', {
          rules: [
            { required: true, message: 'Email is required' },
            { type: 'email', message: 'Must be an email address' },
          ],
        })(<Input size="large" type="text" placeholder="Email" />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password', {
          rules: [
            { required: true, message: 'Password is required' },
            { min: 8, message: 'Must be at least 8 characters in length' },
            { validator: validateConfirmPassword },
          ],
        })(<Input.Password size="large" type="text" placeholder="Password" />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password_confirm', {
          rules: [
            { required: true, message: 'Email is required' },
            { validator: compareConfirmToPassword },
          ],
        })(
          <Input.Password
            size="large"
            type="text"
            placeholder="Password Confirmation"
            onBlur={handleConfirmBlur}
          />,
        )}
      </Form.Item>
      <Button style={{ width: '100%' }} htmlType="submit">
        Submit
      </Button>
    </Form>
  );

  const successDisplay = () => (
    <Form>
      <Heading>Success! Log In with your new password now.</Heading>
      <Button style={{ width: '100%' }} onClick={() => history.push('/login')}>
        Log In
      </Button>
    </Form>
  );

  const errorMessage = {
    color: 'red',
    fontSize: '0.875rem',
  };

  return (
    <>
      <h2>Account Recovery</h2>
      {success ? successDisplay() : recoveryForm()}
      <p style={errorMessage}>{errorMsg}</p>
    </>
  );
};

export default Form.create()(AccountRecovery);
