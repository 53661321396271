import axios from 'axios';
import { BASE_URL } from './constants';
import { getStoredToken } from './auth';

const request = ({ method = 'get', url, data = {}, params = {}, responseType = 'json' }) => {
  const authHeader = getStoredToken() || '';
  return axios({
    baseURL: BASE_URL,
    method,
    url,
    data,
    params,
    headers: { Authorization: authHeader },
    responseType,
  });
};

export const getRequest = (url, params = {}, responseType = 'json') =>
  request({ method: 'get', url, params, responseType });

export const postRequest = (url, data, params = {}) =>
  request({ method: 'post', url, data, params });

export const putRequest = (url, data, params = {}) => request({ method: 'put', url, data, params });

export const deleteRequest = (url, params = {}) => request({ method: 'delete', url, params });

export const downloadRequest = (url, data = null) => {
  var headers = new Headers();
  headers.append('Authorization', getStoredToken());
  headers.append('Content-Type', 'application/json');

  var init = { headers, method: 'GET' };

  if (data != null) {
    init.method = 'POST';
    init.body = JSON.stringify(data);
  }

  return fetch(`${BASE_URL}${url}`, init);
};
