import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Select, Input } from 'antd';
import { Box, Button } from '../common';
import { QuestionTypePropTypes, QuestionTypeDefaultProps } from './QuestionPropTypes';
import { nameSuffixTypes } from '../../utils/constants';
import { arrayOptions, fieldName } from '../../utils/helpers';
import PrevNextBtnGroup from '../PrevNextBtnGroup';

const NameTypeQuestion = props => {
  const { question, keyData, onSubmit, form, handlePrevious } = props;

  // do any data manipulation before passing off to onSubmit
  const prepData = data => {
    return {
      question,
      response: data,
    };
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(prepData(values));
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <NameFields questionValues={keyData} form={form} />
      <PrevNextBtnGroup handlePrevious={handlePrevious} currentQuestion={question.questionID} />
    </Form>
  );
};

export const NameTypeDisplay = props => {
  const { data } = props;
  return `${data.firstName} ${data.middleName} ${data.lastName} ${data.suffix || ''}`;
};

export const NameFields = props => {
  const {
    questionValues = {},
    namePrefix,
    form,
    form: { getFieldDecorator },
    disabled = false,
  } = props;

  const firstName = fieldName(namePrefix, 'firstName');
  const middleName = fieldName(namePrefix, 'middleName');
  const lastName = fieldName(namePrefix, 'lastName');
  const suffix = fieldName(namePrefix, 'suffix');

  // console.log(questionValues);

  useEffect(
    () => {
      form.resetFields([firstName, middleName, lastName, suffix]);
    },
    [
      questionValues.firstName,
      questionValues.middleName,
      questionValues.lastName,
      questionValues.suffix,
    ],
  );

  return (
    <>
      <Box>
        <Row type="flex" justify="center">
          <Form.Item>
            {getFieldDecorator(firstName, {
              initialValue: questionValues.firstName,
              rules: [{ required: true, message: 'First name is required' }],
            })(<Input size="large" type="text" placeholder="First Name" disabled={disabled} />)}
          </Form.Item>
        </Row>
        <Row type="flex" justify="center">
          <Form.Item>
            {getFieldDecorator(middleName, {
              initialValue: questionValues.middleName,
            })(<Input size="large" type="text" placeholder="Middle Name" disabled={disabled} />)}
          </Form.Item>
        </Row>
        <Row type="flex" justify="center">
          <Form.Item>
            {getFieldDecorator(lastName, {
              initialValue: questionValues.lastName,
              rules: [{ required: true, message: 'Last name is required' }],
            })(<Input size="large" type="text" placeholder="Last Name" disabled={disabled} />)}
          </Form.Item>
        </Row>
        <Row type="flex" justify="center">
          <Form.Item>
            {getFieldDecorator(suffix, {
              initialValue: questionValues.suffix,
            })(
              <Select size="large" disabled={disabled} allowClear placeholder="Suffix">
                {arrayOptions(nameSuffixTypes)}
              </Select>,
            )}
          </Form.Item>
        </Row>
      </Box>
    </>
  );
};

export const NameKeyPropTypes = PropTypes.shape({
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  suffix: PropTypes.oneOf(nameSuffixTypes),
});

NameFields.propTypes = {
  register: PropTypes.func.isRequired,
  questionValues: NameKeyPropTypes.isRequired,
  namePrefix: PropTypes.string,
};
NameFields.defaultProps = {
  namePrefix: '',
};

NameTypeQuestion.propTypes = {
  ...QuestionTypePropTypes,
  keyData: PropTypes.objectOf(NameKeyPropTypes.isRequired).isRequired,
};
NameTypeQuestion.defaultProps = QuestionTypeDefaultProps;

export default Form.create()(NameTypeQuestion);

export const exampleNameTypeQuestion = {
  question: {
    ID: 'example-nametype-id',
    questionID: 1,
    question: 'What is your name?',
    nextQuestionID: 2,
    keyID: 'example-nametype-keyID',
    select: null,
    type: 'Name',
  },
  keyData: {
    firstName: 'example-firstName',
    middleName: 'example-middleName',
    lastName: 'example-lastName',
    suffix: null,
  },
};
