import React, { useState, useContext } from 'react';
import useForm from 'react-hook-form';
import { Form, Input } from 'antd';
import { Box, Button, Flex, Subtitle, Title, Container } from './common';
import NewPassword from './NewPassword';
import { putRequest } from '../utils/network';
import { UserContext } from '../contexts/UserContext';
import useLocalStorage from '../hooks/useLocalStorage';

const FormItem = Form.Item;

const Account = props => {
  const { form, form: { getFieldDecorator } } = props;
  const [user, setUser] = useContext(UserContext);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', user);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit = data => {
    setError('');
    form.validateFields((err, data) => {
      console.log(data);
      if (err) {
        return;
      }
      putRequest(`/users/${user.id}`, data)
        .then(resp => {
          setCurrentUser(data);
          setSuccess(true);
        })
        .catch(error => {
          setError('An error occurred while updating your account. Please try again.');
        });
    });
  };

  const errorStyle = {
    color: 'red',
    fontSize: '0.875rem',
  };

  const successStyle = {
    color: 'green',
    fontSize: '0.875rem',
  };

  const compareConfirmToEmail = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('email')) {
      callback('Email confirmation and email must match');
    } else {
      callback();
    }
  };

  const accountForm = () => (
    <>
      <Subtitle>Personal Information</Subtitle>
      <Box mb={2}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormItem>
            {getFieldDecorator('first_name', {
              initialValue: user.first_name,
              rules: [{ required: true, message: 'First name is required' }],
            })(
              <Input
                size="large"
                type="text"
                name="first_name"
                placeholder="First Name"
                ref={register({})}
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('middle_name', {
              initialValue: user.middle_name,
            })(
              <Input
                size="large"
                type="text"
                name="middle_name"
                placeholder="Middle Name"
                ref={register({})}
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('last_name', {
              initialValue: user.last_name,
              rules: [{ required: true, message: 'Last name is required' }],
            })(
              <Input
                size="large"
                type="text"
                name="last_name"
                placeholder="Last Name"
                ref={register({})}
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('email', {
              initialValue: user.email,
              rules: [{ required: true, message: 'Email is required' }],
            })(
              <Input
                size="large"
                type="text"
                name="email"
                placeholder="Email"
                ref={register({})}
              />,
            )}
          </FormItem>
          <Form.Item>
            {getFieldDecorator('email_confirm', {
              rules: [
                { required: true, message: 'Email address confirmation and email must match' },
                { validator: compareConfirmToEmail },
              ],
            })(
              <Input
                size="large"
                type="text"
                placeholder="Email Confirmation "
                ref={register({})}
              />,
            )}
          </Form.Item>
          <Button style={{ width: '100%' }} htmlType="submit">
            Save
          </Button>
        </Form>
      </Box>
    </>
  );

  const successMessage = 'Your account has been successfully updated.';

  return (
    <>
      <Flex mt={[5, 2]} minHeight={5} pt={3} alignItems="center" justifyContent="center">
        <Box>
          <Title style={{ textAlign: 'center' }}>Account Profile</Title>
        </Box>
      </Flex>
      <Container maxWidth={28} mb={[5, 2]} px={[2, 3, 4]} flexDirection="column">
        {error === '' ? null : <p style={errorStyle}>{error}</p>}
        {success ? <p style={successStyle}>{successMessage}</p> : null}
        {accountForm()}
        <NewPassword />
      </Container>
    </>
  );
};

export default Form.create()(Account);
