// export const BASE_URL = 'https://21cbackend.mm.dev';
// export const BASE_URL = 'http://127.0.0.1:3000';
export const BASE_URL = 'https://api.willingworth.com';
// export const STRIPE_PUBLIC_KEY = 'pk_test_PWIl1dC7h7yQY911afmVTmt000mxL6bQyi';
export const STRIPE_PUBLIC_KEY = 'pk_live_a45KMNnEt8BMird4DRovYkUt00IE8se75a';
export const TOKEN = 'access-token';

export const monthsOptionMap = {
  0: {
    display: 'January',
  },
  1: {
    display: 'February',
  },
  2: {
    display: 'March',
  },
  3: {
    display: 'April',
  },
  4: {
    display: 'May',
  },
  5: {
    display: 'June',
  },
  6: {
    display: 'July',
  },
  7: {
    display: 'August',
  },
  8: {
    display: 'September',
  },
  9: {
    display: 'October',
  },
  10: {
    display: 'November',
  },
  11: {
    display: 'December',
  },
};

export const nameSuffixTypes = ['Esq.', 'Jr.', 'II', 'III', 'IV', 'V', 'VI'];

export const relationshipMap = {
  'Spouse/Partner': {
    display: 'Spouse/Partner',
  },
  Mother: {
    display: 'Mother',
  },
  Father: {
    display: 'Father',
  },
  Sister: {
    display: 'Sister',
  },
  Brother: {
    display: 'Brother',
  },
  Grandparent: {
    display: 'Grandparent',
  },
  Aunt: {
    display: 'Aunt',
  },
  Uncle: {
    display: 'Uncle',
  },
  Cousin: {
    display: 'Cousin',
  },
  Niece: {
    display: 'Niece',
  },
  Nephew: {
    display: 'Nephew',
  },
  'God-child': {
    display: 'God-child',
  },
  'Trusted Friend': {
    display: 'Trusted Friend',
  },
  Other: {
    display: 'Other',
  },
};

export const relationshipMapStatus = status => {
  var relMap = relationshipMap;
  if (
    status != undefined &&
    status['select'] != undefined &&
    ['Single', 'Widowed', 'Divorced'].includes(status['select'])
  ) {
    delete relMap['Spouse/Partner'];
  }
  return relMap;
};

export const relationshipTypes = [
  'Spouse/Partner',
  'Mother',
  'Father',
  'Sister',
  'Brother',
  'Grandparent',
  'Aunt',
  'Uncle',
  'Cousin',
  'Niece',
  'Nephew',
  'God-child',
  'Trusted Friend',
  'Other',
];

export const states = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District Of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
};

export const stateExecutors = {
  AK: 'personal representative',
  AL: 'personal representative',
  AR: 'personal representative',
  AZ: 'personal representative',
  CA: 'personal representative',
  CO: 'personal representative',
  CT: 'executor',
  DC: 'personal representative',
  DE: 'executor',
  FL: 'personal representative',
  GA: 'personal representative',
  HI: 'personal representative',
  IA: 'executor',
  ID: 'personal representative',
  IL: 'executor',
  IN: 'personal representative',
  KS: 'executor',
  KY: 'executor',
  LA: 'executor',
  MA: 'personal representative',
  MD: 'personal representative',
  ME: 'personal representative',
  MI: 'personal representative',
  MN: 'personal representative',
  MO: 'personal representative',
  MS: 'executor',
  MT: 'personal representative',
  NC: 'executor',
  ND: 'personal representative',
  NE: 'personal representative',
  NH: 'executor',
  NJ: 'personal representative',
  NM: 'personal representative',
  NV: 'personal representative',
  NY: 'personal representative',
  OH: 'executor',
  OK: 'executor',
  OR: 'personal representative',
  PA: 'personal representative',
  RI: 'executor',
  SC: 'personal representative',
  SD: 'personal representative',
  TN: 'executor',
  TX: 'executor',
  UT: 'personal representative',
  VA: 'personal representative',
  VT: 'executor',
  WA: 'personal representative',
  WI: 'personal representative',
  WV: 'personal representative',
  WY: 'personal representative',
};

export const progressQuestions = [1, 34, 57, 1000]; // large number on the end -- payment is last step, but probably won't show progress
