import styled from 'styled-components';
import {
  space,
  height,
  width,
  maxWidth,
  color,
  fontSize,
  textAlign,
  display,
  alignSelf,
} from 'styled-system';

const Box = styled.div`
  ${color}
  ${fontSize}
  ${textAlign}
  ${space}
  ${width}
  ${height}
  ${maxWidth}
  ${display}
  ${alignSelf}

`;

Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...height.propTypes,
  ...color.propTypes,
  ...maxWidth.propTypes,
  ...display.propTypes,
  ...alignSelf.propTypes,
};

Box.defaultProps = {};

Box.displayName = 'Box';

export default Box;
