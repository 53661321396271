import React from 'react';
import styled from 'styled-components';
import {
  color,
  space,
  minWidth,
  borders,
  boxShadow,
  fontFamily,
  fontWeight,
  buttonStyle,
  variant,
  width,
} from 'styled-system';

import theme from '../theme';

const buttonSize = variant({
  prop: 'size',
  key: 'buttonSizes',
});

const StyledButton = styled.button`
  ${color}
  ${space}
  ${minWidth}
  ${borders}
  ${boxShadow}
  ${fontFamily}
  ${fontWeight}
  ${buttonStyle}
  ${buttonSize}
  ${width}
  & {
    -webkit-font-smoothing: antialiased;
    appearance: none;
    user-select: none;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    overflow: hidden;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  > span.button-text {
    position: relative;
    z-index: 1;
    user-select: none;
    pointer-events: none;
    line-height: 1;
  }
  & {
    min-width: ${props => props.minWidth};
  }
`;

const ButtonBody = ({ children }) => {
  return <span className="button-text">{children}</span>;
};

const Button = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledButton {...props} ref={ref}>
      {children}
    </StyledButton>
  );
});

Button.defaultProps = {
  theme,
  as: 'button',
  size: 'medium',
  m: 0,
  px: 2,
  py: 0,
  fontFamily: theme.fonts.sansSerifAlt,
  fontWeight: 600,
  color: 'white',
  bg: 'silver',
  mainColor: theme.colors.primary,
  contrastColor: 'white',
  border: 'none',
  variant: 'primary',
  maxWidth: '350px',
};

Button.displayName = 'Button';

export { StyledButton, ButtonBody };

export default Button;
