import { Container, Flex, Heading, theme } from './common';
import { Divider, Icon, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../utils/network';

const { confirm } = Modal;

var moment = require('moment');

const pageSize = 20;

const UsersList = props => {
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const [launched, setLaunched] = useState(false);

  useEffect(() => {
    if (!launched) {
      fetchUsers(0);
    }
  });

  const fetchUsers = page => {
    getRequest('/users', {
      page,
      per_page: pageSize,
    }).then(({ data: respData }) => {
      setUsers(respData.users);
      const paginationUpdate = { ...pagination };
      paginationUpdate.current = page;
      paginationUpdate.total = respData.count;
      setPagination(paginationUpdate);
      setLaunched(true);
    });
  };

  const displayName = user => `${user.first_name} ${user.last_name}`;

  const joinedDisplay = time => `${moment(time).format('MMM DD, YYYY')}`;

  const handleChange = (p, filters, sorter) => {
    fetchUsers(p.current);
  };

  const deactivateUser = id => {
    postRequest(`/users/${id}/deactivate`, {
      // deactivation_notes: 'testing notes',
    }).then(() => {
      fetchUsers(pagination.current);
    });
  };

  function confirmDeactivate(id) {
    confirm({
      icon: <Icon type="warning" theme="twoTone" twoToneColor={theme.colors.red} />,
      title: 'Are you sure?',
      content:
        'Deactivating this user is permanent and will not allow their email to be used again. Are you sure?',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deactivateUser(id);
      },
      onCancel() {},
    });
  }

  const tableLink = {
    color: theme.colors.darkGray,
    fontWeight: '600',
    lineHeight: 1.2,
    textAlign: 'center',
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'last_name',
      sortDirections: ['descend', 'ascend'],
      key: 'last_name',
      render: (text, user) => displayName(user),
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Postal Code',
      dataIndex: 'postal_code',
    },
    {
      title: 'Payment Made',
      render: (text, user) => (user.Purchases != null && user.Purchases.length > 0 ? 'Yes' : 'No'),
      dataIndex: 'Purchases',
      filters: [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' },
      ],
    },
    {
      title: 'Joined',
      dataIndex: 'created_at',
      render: text => joinedDisplay(text),
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, user) => (
        <span>
          <a
            style={tableLink}
            onClick={e => {
              e.preventDefault();
              confirmDeactivate(user.id);
            }}
          >
            Deactivate
          </a>
        </span>
      ),
    },
  ];

  return (
    <>
      <Heading>Users</Heading>
      <Container maxWidth={72} p={[2, 3, 4]} mb={[2, 3]} flexDirection="column" flexAlign="start">
        <Table
          columns={columns}
          dataSource={users}
          pagination={pagination}
          onChange={handleChange}
          rowKey={user => user.id}
        />
      </Container>
    </>
  );
};

export default UsersList;
